import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selfDataSelector } from '../../../redux/config/selfData';
import api from '../../../api';
import { sortArray } from '../../../utils/arrayUtil';
import {
  IGetNursingHomeCareRecordParams,
  IGetNursingHomeCareRecordRes,
} from '../../../types/api/traversal/getNursingHomeCareRecord';

interface IFetchParams {
  nursingHomeId: number;
  nursingHomeUnitId?: number | null;
  from?: string | null;
  to?: string | null;
}

export const useFetchNursingHomeCareRecord = (
  shouldInitFetch: boolean = false, //初回fetchをするかどうか
  nursingHomeUnitId: number | null = null,
  from: string | null = null,
  to: string | null = null,
  isDeviceOnly: boolean = false // 装着中のみフィルタしたいときtrue
) => {
  const { nursingHomeId } = useParams<{ nursingHomeId: string }>();
  const { selfUser } = useSelector(selfDataSelector);
  const [nursingHomeCareRecord, setNursingHomeCareRecord] = React.useState<IGetNursingHomeCareRecordRes>();

  const fetchNursingHomeCareRecord = async (
    nursingHomeUnitId: number | null = null,
    from: string | null = null,
    to: string | null = null,
    isDeviceOnly: boolean = false
  ) => {
    const params: IGetNursingHomeCareRecordParams = {
      nursingHomeId: +nursingHomeId,
      nursingHomeUnitId,
      from,
      to,
    };

    // UnitIdの指定がない場合は、自身のUnitIdを優先して取得
    if (selfUser.hasUnitPerm && selfUser.nursingHomeUnitId && !nursingHomeUnitId)
      params.nursingHomeUnitId = selfUser.nursingHomeUnitId;

    if (!from) delete params.from;
    if (!to) delete params.to;
    if ([0, null].includes(nursingHomeUnitId)) delete params.nursingHomeUnitId;

    let result = await api.get('/traversal/nursing-homes/:nursingHomeId/care-records', {
      params,
      timeout: 5 * 60 * 1000, // 5分に設定
    });

    if (isDeviceOnly) {
      result = {
        ...result,
        residents: result.residents.filter((resident) => resident.dfreeDeviceId),
      };
    }

    result.residents = sortArray(result.residents, 'residentName');
    result.residents = sortArray(result.residents, 'roomName');
    result.residents = sortArray(result.residents, 'nursingHomeUnitName');
    setNursingHomeCareRecord(result);
    return result; // TODO: 値を直接returnし、useStateを使っていないため修正する
  };

  React.useEffect(() => {
    // 初回fetchはUnitIdの指定がない場合は、自身のUnitIdを優先して取得
    // それ以外はUnitIdなし（全ユニット取得）
    if (!shouldInitFetch || !selfUser.nursingHomeId) return;
    if (selfUser.hasUnitPerm && selfUser.nursingHomeUnitId)
      fetchNursingHomeCareRecord(selfUser.nursingHomeUnitId, from, to, isDeviceOnly);
    else if (nursingHomeUnitId)
      fetchNursingHomeCareRecord(isDeviceOnly ? null : nursingHomeUnitId, from, to, isDeviceOnly);
    else fetchNursingHomeCareRecord(null, from, to, isDeviceOnly);
  }, [selfUser]);

  return { nursingHomeCareRecord, fetchNursingHomeCareRecord };
};
