import React from 'react';
import { css } from '@emotion/react';
import dayjs, { Dayjs } from 'dayjs';
import { Order } from '../../../types/api/order/getOrders';
import { ExcretionTable } from './excreteTable';
import { useFetchNursingHomeCareRecord } from '../../../hooks/api/traversal/useFetchNursingHomeCareRecord';
import { ResidentListHeader } from '../../../components/residentListHeader';
import { DateSelector } from '../../../components/mui/dateSelector';
import { useSelector } from 'react-redux';
import { filterConditionSelector } from '../../../redux/config/filterCondition';
import { useTranslation } from 'react-i18next';

export interface IGetOrder extends Order {
  displayUnitName: string; // 施設名がないものを施設全体として変換したもの
  displayTimestamp: string; // 日付をutcからタイムゾーン変換したもの
}

const Container = css`
  padding: 0 16px;
`;

const RightElementContainer = css`
  margin-right: 16px;
`;

const EmptyResidentContainer = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  font-size: 16px;
`;

export const ExcretionTableList = React.memo(() => {
  const initialDate = dayjs().set('hour', 0).set('minute', 0).set('second', 0).utc();
  const initialDateEnd = initialDate.add(1, 'day');
  const { conditions, unitId } = useSelector(filterConditionSelector);
  const [selectedDate, setSelectDate] = React.useState<Dayjs>(initialDate);
  const [selectedUnitId, setSelectedUnitId] = React.useState<number | null>(unitId);
  const { t } = useTranslation();
  const { nursingHomeCareRecord, fetchNursingHomeCareRecord } = useFetchNursingHomeCareRecord(
    true,
    selectedUnitId,
    initialDate.format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
    initialDateEnd.format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
    conditions === 'deviceOnly'
  );

  const handleUpdate = () => {
    fetchNursingHomeCareRecord(
      selectedUnitId,
      selectedDate.format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
      selectedDate.add(1, 'day').format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
      conditions === 'deviceOnly'
    );
  };

  const handleChangeUnit = (unitId: number | null, isDeviceOnly = false) => {
    setSelectedUnitId(unitId);
    fetchNursingHomeCareRecord(
      unitId,
      selectedDate.format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
      selectedDate.add(1, 'day').format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
      isDeviceOnly
    );
  };

  const handleChangeDate = (newDate: Dayjs) => {
    setSelectDate(newDate);
    fetchNursingHomeCareRecord(
      selectedUnitId,
      newDate.format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
      newDate.add(1, 'day').format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
      conditions === 'deviceOnly'
    );
  };

  // 5分ごとに入居者データを更新する
  React.useEffect(() => {
    const timer = setInterval(() => {
      fetchNursingHomeCareRecord(
        selectedUnitId ? selectedUnitId : unitId ? +unitId : null,
        selectedDate.format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
        selectedDate.add(1, 'day').format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
        conditions === 'deviceOnly'
      );
    }, 1000 * 60 * 5);
    return () => {
      clearInterval(timer);
    };
  }, [selectedDate, selectedUnitId]);

  return (
    <div css={Container}>
      <ResidentListHeader
        title={t('excretionTable', '排泄表')}
        conditions={conditions}
        unitId={selectedUnitId}
        rightElement={
          <div css={RightElementContainer}>
            <DateSelector
              newValue={selectedDate}
              minDate={initialDate.subtract(3, 'years')}
              maxDate={initialDate}
              onChange={handleChangeDate}
            />
          </div>
        }
        onUpdate={handleUpdate}
        onChangeUnit={handleChangeUnit}
      />

      {nursingHomeCareRecord && nursingHomeCareRecord.residents.length ? (
        nursingHomeCareRecord.residents.map((v, i) => {
          return <ExcretionTable resident={v} key={i} />;
        })
      ) : (
        <div css={EmptyResidentContainer}>{t('thereAreNoResidents', '入居者が居ません')}</div>
      )}
    </div>
  );
});
