import React from 'react';
import { useSelector } from 'react-redux';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { IResident } from '../../../types/api/traversal/getNursingHomeResidents';
import { color } from '../../../styles';
import { OutlineButton } from '../../../components/outlineButton';
import { Button } from '../../../components/mui/button';
import { ResidentItemHeader } from '../../../components/residentItemHeader';
import { useHistory } from 'react-router-dom';
import dayjs, { locale } from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);
import { useTranslation } from 'react-i18next';

require('highcharts/modules/accessibility')(Highcharts);

interface Props {
  resident: IResident;
  dateRange: { start: string; end: string };
  fetch: () => void;
  openDialog: (resident: IResident) => void;
  resetNotification: (residentId: number) => void;
}

const NotificationCardDefault = css`
  border: 5px solid ${color.notification_navy};
  background: ${color.notification_navy_bg};
`;

const NotificationCardYellow = css`
  border: 5px solid ${color.notification_yellow};
  background: ${color.notification_yellow_bg};
`;

const NotificationCardRed = css`
  border: 5px solid ${color.notification_red};
  background: ${color.notification_red_bg};
`;

const NotificationCardPink = css`
  border: 5px solid ${color.notification_pink};
  background: ${color.notification_pink_bg};
`;

const ResidentItemContainer = styled.div<{ resident: IResident }>`
  background: ${color.white};
  box-sizing: border-box;
  border-radius: 8px;
  margin-bottom: 16px;
  .highcharts-scatter-series {
    .highcharts-point {
      opacity: 1;
    }
  }
  // 通知カバー用
  ${({ resident }) => {
    if (resident.bigBladderNotification) {
      return NotificationCardYellow;
    }
    if (resident.urinatedNotification) {
      return NotificationCardPink;
    }
    if (resident.gotUpNotification) {
      return NotificationCardRed;
    }
    if (
      resident.sensorErrorNotification ||
      resident.upsideDownNotification ||
      resident.zeroResetNotification ||
      resident.urineResetNotification
    ) {
      return NotificationCardDefault;
    }
  }};
`;

const HeaderRightContainer = css`
  display: flex;
  flex-wrap: wrap-reverse;
  align-items: center;
  justify-content: end;
  min-height: 48px;
  gap: 8px;
`;

const ResidentContent = css`
  padding: 16px;
  display: flex;
  border-top: 1px solid ${color.border_gray};
  gap: 24px;
`;

const LeftContainer = css`
  position: relative;
`;

const UrinationInfoContainer = css`
  position: relative;
  min-width: 200px;
  min-height: 128px;
  top: -8px;
`;

const CurrentUrineSize = styled.div<{ resident: IResident }>`
  position: absolute;
  font-family: 'Oswald', sans-serif;
  bottom: -4px;
  right: 72px;
  font-weight: bold;
  font-size: 134px;
  line-height: 1em;
  text-align: right;
  color: #7bd6e7;
  ${({ resident }) => {
    if (resident.bigBladderNotification) {
      return `color: ${color.notification_yellow};`;
    }
    if (resident.urinatedNotification) {
      return `color: ${color.notification_pink};`;
    }
    if (resident.gotUpNotification) {
      return `color: ${color.notification_red};`;
    }
  }};
`;

const NoUrineSize = css`
  ${CurrentUrineSize};
  color: ${color.thin_gray};
`;

const UrineBorder = css`
  transform: rotate(-65.22deg);
  width: 42px;
  height: 1px;
  position: absolute;
  bottom: 20px;
  right: 40px;
  background: #aaaaaa;
`;

const ThresholdLineLabel = css`
  position: absolute;
  padding-bottom: 2px;
  letter-spacing: 0;
  text-align: center;
  font-size: 12px;
  line-height: 1em;
  bottom: 24px;
  right: -4px;
`;

const ThresholdLine = css`
  position: absolute;
  color: ${color.dark_gray};
  bottom: 0;
  right: 0;
  font-family: 'Oswald', sans-serif;
  font-size: 20px;
  line-height: 1em;
  width: 44px;
  text-align: center;
`;

const RecordButton = styled(Button)`
  margin-top: 8px;
  border-radius: 24px;
  padding: 8px 24px;
`;

const GraphContainer = css`
  width: 100%;
`;

const ZeroResetBlcok = css`
  display: inline-block;
  padding: 0 10px;
`;

const NotificationButton = styled(Button)<{ resident: IResident }>`
  width: 240px;
  font-size: 20px;
  font-weight: bold;
  color: ${color.white};
  border-radius: 40px;
  background: ${color.notification_navy};
  &:hover {
    background: ${color.notification_navy};
    opacity: 0.8;
  }
  ${({ resident }) => {
    if (resident.bigBladderNotification) {
      return NotificationButtonYellow;
    }
    if (resident.urinatedNotification) {
      return NotificationButtonPink;
    }
    if (resident.gotUpNotification) {
      return NotificationButtonRed;
    }
  }};
`;

const FaqButton = styled(Button)`
  width: 240px;
  font-size: 20px;
  font-weight: bold;
  color: ${color.white};
  border-radius: 40px;
  background: ${color.notification_navy};
  &:hover {
    background: ${color.notification_navy};
    opacity: 0.8;
  }
  }};
`;

const NotificationButtonYellow = css`
  color: ${color.dark_gray};
  background: ${color.notification_yellow};
  &:hover {
    background: ${color.notification_yellow};
  }
`;

const NotificationButtonPink = css`
  color: ${color.white};
  background: ${color.notification_pink};
  &:hover {
    background: ${color.notification_pink};
  }
`;

const NotificationButtonRed = css`
  color: ${color.white};
  background: ${color.notification_red};
  &:hover {
    background: ${color.notification_red};
  }
`;

const NotificationCoverContainer = css`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
`;

const NotificationInfoContainer = css`
  display: flex;
  justify-content: start;
  align-items: start;
`;

const NotificationContentContainer = css`
  display: flex;
  gap: 8px;
  flex-direction: column;
  justify-content: start;
  align-items: start;
`;

const NotificationTitle = css`
  padding-top: 16px;
  font-size: 24px;
  color: ${color.dark_gray};
  font-weight: bold;
`;

const NotificationDetailText = css`
  color: ${color.notification_navy};
  font-weight: bold;
  font-size: 14px;
`;

const NotificationIcon = css`
  margin-right: 16px;
`;

const TimeStampButton = styled.div`
  padding: 8px 16px;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  border: 1px dashed ${color.dark_gray};
  color: ${color.notification_red};
  border-radius: 8px;
`;

const TimeStampContainer = css`
  display: flex;
  justify-content: start;
  align-items: start;
  gap: 16px;
`;

export const DfreeGraphListItem = React.memo(
  ({ resident, dateRange, fetch, openDialog, resetNotification, ...props }: Props) => {
    const {
      id,
      name,
      serialNumber,
      carePlanMemo,
      nursingHomeUnitName,
      roomName,
      bigBladderNotificationSetting,
      sensorReactions,
      bladderThresholdPercentage,
      sensorNotifications,
      careRecordPlots,
    } = resident;
    const history = useHistory();
    const [options, setOptions] = React.useState<Highcharts.Options>({});
    const { t, i18n } = useTranslation();
    locale(i18n.language);

    const handleClickRecord = React.useCallback(() => {
      openDialog(resident);
    }, []);

    const handleNotificationReset = React.useCallback(() => {
      resetNotification(resident.id);
    }, []);

    const handleFaqButton = React.useCallback(() => {
      i18n.language === 'ja'
        ? window.open('https://dfree.biz/img/pdf/professional_solution.pdf', '_blank')
        : window.open('https://www.dfreeus.biz/support', '_blank');
    }, []);

    const handleGoDetail = React.useCallback(() => {
      history.push(`./residents/${id}/graph`);
    }, []);

    const handleGoEdit = React.useCallback(() => {
      history.push(`./residents/${id}/edit`);
    }, []);

    const getYGrids = React.useCallback(() => {
      let grids: Highcharts.YAxisPlotLinesOptions[] = [];

      for (let i = 0; i < 10; i++) {
        grids = [
          ...grids,
          {
            color: '#CCCCCC',
            dashStyle: 'Solid',
            value: i,
            width: 1,
          },
        ];
      }
      grids = [
        ...grids,
        {
          color: bigBladderNotificationSetting ? '#FFBB00' : '#C0C0C0',
          dashStyle: 'Solid',
          value: (bladderThresholdPercentage ?? 0) / 10 - 0.03,
          width: 2,
          zIndex: 1,
        },
      ];
      return grids;
    }, [bigBladderNotificationSetting, bladderThresholdPercentage]);

    React.useEffect(() => {
      setOptions({
        chart: {
          type: 'area',
          zoomType: 'x',
          height: 180,
        },
        time: {
          timezoneOffset: new Date().getTimezoneOffset(),
        },
        title: {
          text: '',
        },
        xAxis: {
          type: 'datetime',
          min: Date.parse(dateRange.start),
          max: Date.parse(dateRange.end),
          showEmpty: true,
          opposite: false,
          gridLineWidth: 1,
          lineWidth: 0,
          tickWidth: 0,
          minPadding: 0,
          maxPadding: 0,
          plotLines: [
            {
              color: '#CCCCCC',
              dashStyle: 'Solid',
              value: Date.parse(dateRange.start),
              width: 0.5,
            },
            {
              color: '#CCCCCC',
              dashStyle: 'Solid',
              value: Date.parse(dateRange.end),
              width: 0.5,
            },
          ],
          dateTimeLabelFormats: {
            minute: '%H:%M',
            hour: '%H:00',
            day: t('graphDayFormat', '%m月%d日'),
            week: t('graphDayFormat', '%m月%d日'),
            month: t('graphYearFormat', '%m月%d日'),
          },
          crosshair: {
            width: 2,
          },
        },
        yAxis: {
          title: {
            text: '',
          },
          labels: {
            formatter: function () {
              return this.value as string;
            },
          },
          plotLines: getYGrids(),
          max: 10,
          min: 0,
          tickInterval: 2,
          crosshair: {
            width: 2,
          },
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          area: {
            fillColor: {
              linearGradient: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 1,
              },
              stops: [
                [0, 'rgba(123, 214, 231, 0.5)'],
                [1, 'rgba(123, 214, 231, 0.5)'],
              ],
            },
            lineWidth: 3,
            states: {
              hover: {
                lineWidth: 3,
              },
            },
            threshold: null,
          },
          series: {
            marker: {
              enabled: false,
            },
          },
        },
        series: [
          // @ts-ignore
          {
            name: t('urineGraphLabel', '尿量'),
            // @ts-ignore
            data: sensorReactions,
            tooltip: {
              headerFormat: '<b>{series.name}</b><br>',
              pointFormat: t('graphDateTimeFormat', '{point.name}<br />{point.x:%m月%d日 %H時%M分}'),
            },
          },
          {
            name: t('notification', '通知'),
            marker: {
              enabled: true,
              symbol: 'circle',
            },
            data: (sensorNotifications ?? [])
              .filter((notification) => {
                const { notificationType } = notification;
                return (
                  notificationType === 'urinated' || notificationType === 'bigBladder' || notificationType === 'gotUp'
                );
              })
              .map((notification) => {
                const { notificationType, timestamp } = notification;
                let label = '';
                let color = '';
                if (notificationType === 'urinated') {
                  label = t('checkGraphLabel', 'でたかも');
                  color = 'rgba(255, 143, 165, 0.8)';
                } else if (notificationType === 'bigBladder') {
                  label = t('almostGraphLabel', 'そろそろ');
                  color = 'rgba(255, 187, 0, 0.8)';
                } else {
                  label = t('gotUpGraphLabel', '起き上がり');
                  color = 'rgba(255, 50, 35, 0.8)';
                }
                return {
                  x: Date.parse(timestamp),
                  y: 0,
                  name: label,
                  color: color,
                };
              }),
            type: 'line', // scatterだとエリア外のマウスオーバーでtooltipがでないためタイプをlineに
            lineWidth: 0,
            states: {
              hover: {
                lineWidth: 0,
                lineWidthPlus: 0,
              },
            },
            tooltip: {
              headerFormat: '<b>{series.name}</b><br>',
              pointFormat: t('graphDateTimePlotFormat', '{point.name}<br />{point.x:%m月%d日 %H時%M分}'),
            },
            stickyTracking: false,
          },
          {
            name: t('careHistory', 'ケア履歴'),
            marker: {
              enabled: true,
              symbol: 'square',
            },
            zIndex: 3,
            data: (careRecordPlots || []).map((careRecordPlots) => {
              const { toilet, pad, timestamp } = careRecordPlots;
              const label = `${t('toilet', 'トイレ')}: ${toilet ? t('yes', '有') : t('no', '無')} ${t(
                'diaper',
                'おむつ/パッド'
              )}: ${pad ? t('yes', '有') : t('no', '無')}`;
              let color;
              if (toilet && !pad) color = '#72fbfd';
              if (toilet && pad) color = '#75f94c';
              if (!toilet && pad) color = '#CCCCCC';
              if (!toilet && !pad) color = '#6D6D6D';
              return {
                x: Date.parse(timestamp),
                y: 10,
                name: label,
                color,
              };
            }),
            type: 'line', // scatterだとエリア外のマウスオーバーでtooltipがでないためタイプをlineに
            lineWidth: 0,
            states: {
              hover: {
                lineWidth: 0,
                lineWidthPlus: 0,
              },
            },
            tooltip: {
              headerFormat: '<b>{series.name}</b><br>',
              pointFormat: t('graphDateTimePlotFormat', '{point.name}<br />{point.x:%m月%d日 %H時%M分}'),
            },
          },
        ],
        credits: {
          enabled: false,
        },
      });
    }, []);

    const BatteryStatusImgURL = (batteryRemaining: number) => {
      const baseURL = '/images/residents/';
      if (batteryRemaining > 90) {
        return baseURL + 'battery-100.svg';
      }
      if (batteryRemaining < 90 && batteryRemaining > 60) {
        return baseURL + 'battery-90.svg';
      }
      if (batteryRemaining < 60 && batteryRemaining > 15) {
        return baseURL + 'battery-60.svg';
      }
      if (batteryRemaining < 15 && batteryRemaining > 0) {
        return baseURL + 'battery-15.svg';
      }
      return baseURL + 'battery-0.svg';
    };

    return (
      <>
        <ResidentItemContainer {...props} resident={resident}>
          <ResidentItemHeader
            name={name}
            serialNumber={serialNumber}
            unitName={nursingHomeUnitName}
            roomName={roomName}
            memo={carePlanMemo}
            rightElement={
              <div css={HeaderRightContainer}>
                {resident.batteryRemaining != null && (
                  <img src={BatteryStatusImgURL(resident.batteryRemaining)} alt='バッテリー残量' />
                )}
                <img
                  src={
                    '/images/residents/' +
                    (resident.bigBladderNotificationSetting ? 'on-bigbradder.svg' : 'off-bigbradder.svg')
                  }
                  alt='そろそろ通知'
                />
                <img
                  src={
                    '/images/residents/' +
                    (resident.urinatedNotificationSetting ? 'on-urination.svg' : 'off-urination.svg')
                  }
                  alt='出たかも通知'
                />
                <img
                  src={'/images/residents/' + (resident.gotUpNotificationSettging ? 'on-gotup.svg' : 'off-gotup.svg')}
                  alt='起き上がり通知'
                />
                <OutlineButton
                  onClick={handleGoDetail}
                  icon={<img src='/images/icons/graph.svg' alt='グラフ分析' />}
                  bgColor={color.white}
                >
                  {t('analysis', 'グラフ分析')}
                </OutlineButton>
                <OutlineButton
                  onClick={handleGoEdit}
                  icon={<img src='/images/icons/resident-setting.svg' alt='入居者設定' />}
                  bgColor={color.white}
                >
                  {t('edit', '編集')}
                </OutlineButton>
              </div>
            }
          />
          <div css={ResidentContent}>
            {(() => {
              if (
                !resident.zeroResetNotification &&
                !resident.sensorErrorNotification &&
                !resident.upsideDownNotification &&
                !resident.urineResetNotification
              ) {
                return (
                  <div css={LeftContainer}>
                    <div css={UrinationInfoContainer}>
                      <CurrentUrineSize
                        css={resident.urineSize != null ? CurrentUrineSize : NoUrineSize}
                        resident={resident}
                      >
                        {resident.urineSize != null
                          ? resident.urineVolumeResetFlg // グラフ0リセットフラグが立っている時は強制的に尿量を0にする
                            ? '0'
                            : Math.round(resident.urineSize / 10)
                          : '-'}
                      </CurrentUrineSize>
                      <div css={UrineBorder}></div>
                      <div css={ThresholdLineLabel}>
                        {t('almost', 'そろそろ')}
                        <br />
                        {t('threshold', 'ライン')}
                      </div>
                      <div css={ThresholdLine}>
                        {resident.bladderThresholdPercentage ? resident.bladderThresholdPercentage / 10 : '-'}
                      </div>
                    </div>
                    {!resident.coverTimestamp && (
                      <RecordButton variant='contained' onClick={handleClickRecord}>
                        {t('recordButton', '記録')}
                      </RecordButton>
                    )}
                  </div>
                );
              }
            })()}

            {/* 通知カバーがあるときはグラフの代わりに通知コンテンツを表示 */}
            {!resident.coverTimestamp ? (
              <div css={GraphContainer}>
                <HighchartsReact highcharts={Highcharts} options={options} />
              </div>
            ) : (
              <div css={NotificationCoverContainer}>
                <div css={NotificationInfoContainer}>
                  {resident.bigBladderNotification && (
                    <img
                      src='/images/notification/large_icon/large_bigbladder.svg'
                      alt='そろそろ通知'
                      css={NotificationIcon}
                    />
                  )}
                  {resident.urinatedNotification && (
                    <img
                      src='/images/notification/large_icon/large_urinated.svg'
                      alt='出たかも通知'
                      css={NotificationIcon}
                    />
                  )}
                  {resident.gotUpNotification && (
                    <img
                      src='/images/notification/large_icon/large_gotUp.svg'
                      alt='起き上がり通知'
                      css={NotificationIcon}
                    />
                  )}
                  {resident.zeroResetNotification && (
                    <img
                      src='/images/notification/large_icon/large_zeroReset.svg'
                      alt='装着から尿量が1時間以上0'
                      css={NotificationIcon}
                    />
                  )}

                  {(() => {
                    if (
                      resident.sensorErrorNotification ||
                      resident.upsideDownNotification ||
                      resident.urineResetNotification
                    ) {
                      return (
                        <img
                          src='/images/notification/large_icon/large_alert.svg'
                          alt='アラート'
                          css={NotificationIcon}
                        />
                      );
                    }
                  })()}

                  <div css={NotificationContentContainer}>
                    <div css={NotificationTitle}>
                      {resident.bigBladderNotification && t('exceededAlmostThreshold', 'そろそろラインを超えました')}
                      {resident.urinatedNotification && t('declineInVolume', 'でたかもしれません')}
                      {resident.gotUpNotification && t('detectedGettingUp', '起き上がりを検知しました')}
                      {resident.sensorErrorNotification && t('sensorNotResponding', 'DFreeが外れています')}
                      {resident.upsideDownNotification && t('dFreeIsPlacedUpsideDown', 'DFreeの向きが上下逆です')}
                      {resident.zeroResetNotification &&
                        t('urineLevelHasBeen0For1HourOrMore', '装着から尿量が2時間以上0です')}
                      {resident.urineResetNotification && t('measurementResumed', '測定が再開されました')}
                    </div>
                    {resident.zeroResetNotification && (
                      <div css={NotificationDetailText}>
                        {t('pleaseCheckTheFollowing', '以下を確認してください。')}
                        <br />
                        {t('tapeGelSensorPosition', '・テープの固定　・ジェルの量　・DFreeの浮き　・装着位置')}
                      </div>
                    )}
                    {resident.sensorErrorNotification && (
                      <div css={NotificationDetailText}>
                        {t('pleaseCheckTheFollowing', '以下を確認してください。')}
                        <br />
                        {/* eslint no-irregular-whitespace: 0 */}
                        {t('tapeGelSensorPosition', '・テープの固定　・ジェルの量　・DFreeの浮き　・装着位置')}
                      </div>
                    )}
                    <div css={TimeStampContainer}>
                      <TimeStampButton>
                        <img src='/images/icons/alarm.svg' alt='経過時間' />
                        {resident.coverTimestamp && dayjs.utc(resident.coverTimestamp).fromNow(true)}
                        {t('havePassed', '経過')}
                      </TimeStampButton>
                      {resident.upsideDownNotification && (
                        <img src='/images/notification/large_icon/upside_down.svg' alt='DFreeが上下逆' />
                      )}
                    </div>
                  </div>
                </div>
                {resident.bigBladderNotification ? (
                  <NotificationButton resident={resident} onClick={handleClickRecord}>
                    {t('recordButton', '記録')}
                  </NotificationButton>
                ) : resident.urinatedNotification ? (
                  <NotificationButton resident={resident} onClick={handleClickRecord}>
                    {t('recordButton', '記録')}
                  </NotificationButton>
                ) : resident.zeroResetNotification ? (
                  <>
                    <div css={ZeroResetBlcok}>
                      <FaqButton onClick={handleFaqButton}>{t('faqButton', '困ったときは')}</FaqButton>

                      <NotificationButton resident={resident} onClick={handleNotificationReset}>
                        OK
                      </NotificationButton>
                    </div>
                  </>
                ) : (
                  <NotificationButton resident={resident} onClick={handleNotificationReset}>
                    OK
                  </NotificationButton>
                )}
              </div>
            )}
          </div>
        </ResidentItemContainer>
      </>
    );
  }
);
