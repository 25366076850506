import React from 'react';
import { css } from '@emotion/react';
import { color } from '../styles';

interface Props {
  title: string;
  children: React.ReactNode;
  rightElement?: React.ReactNode;
}

const Container = css`
  margin: 24px auto;
  width: 96%;
  border-radius: 8px;
  background: ${color.white};
  max-width: 1080px;
  min-height: 82vh;
`;

const titleContainer = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  border-bottom: 2px solid ${color.bg_gray};
  span {
    font-size: 18px;
    font-weight: bold;
  }
`;

const BodyContainer = css`
  padding: 24px;
`;

export const PanelContainer = React.memo(({ title, children, rightElement }: Props) => {
  return (
    <div css={Container}>
      <div css={titleContainer}>
        <span>{title}</span>
        {rightElement && rightElement}
      </div>
      <div css={BodyContainer}>{children}</div>
    </div>
  );
});

