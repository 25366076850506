import React from 'react';
import { css } from '@emotion/react';
import { styled, Menu, MenuItem, Button, ListItemIcon, MenuProps, ListItemText } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import CheckIcon from '@mui/icons-material/Check';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { color } from '../styles';
import { useDispatch, useSelector } from 'react-redux';
import { selfDataSelector, selfDataSlice } from '../redux/config/selfData';
import { IGetUnitRes } from '../types/api/unit/getUnit';
import { filterConditionSelector, filterConditionSlice } from '../redux/config/filterCondition';
import api from '../api';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

interface Props {
  title: string;
  conditions: 'all' | 'deviceOnly' | 'unit';
  unitId: number | null;
  rightElement?: React.ReactNode;
  onUpdate: () => void;
  onChangeUnit: (unitId: number | null, isDeviceOnly?: boolean) => void; // unitもしくは施設全体
}

const Container = css`
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TitleContainer = css`
  display: flex;
  align-items: center;
`;

const Title = css`
  font-size: 20px;
  font-weight: bold;
  margin-right: 16px;
`;

const StyledButton = styled(Button)({
  borderRadius: '100px',
  color: `${color.dark_gray}`,
  background: `${color.white}`,
  '&:hover': {
    backgroundColor: `${color.white}`,
  },
});

const StyledMenu = styled((props: MenuProps) => <Menu {...props} />)(() => ({
  '& .MuiPaper-root': {
    '& .MuiMenuItem-root': {
      justifyContent: 'flex-end',
    },
  },
}));

const RightElementContainer = css`
  display: flex;
`;

export const ResidentListHeader = React.memo(
  ({ title, conditions, unitId, rightElement, onUpdate, onChangeUnit }: Props) => {
    const { selfUser, selfUnits } = useSelector(selfDataSelector);
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [units, setUnits] = React.useState<IGetUnitRes[]>();
    const { t } = useTranslation();
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleChangeDeviceOnly = React.useCallback(() => {
      onChangeUnit(selfUser.nursingHomeUnitId ?? null, true);
      dispatch(
        filterConditionSlice.actions.setFilterCondition({
          conditions: 'deviceOnly',
          unitId: selfUser.nursingHomeUnitId ? selfUser.nursingHomeUnitId : unitId,
        })
      );
      localStorage.setItem('condition', 'deviceOnly');
      localStorage.setItem('unit', selfUser.nursingHomeUnitId ? String(selfUser.nursingHomeUnitId) : 'null');
      handleClose();
    }, [onChangeUnit, selfUser]);

    const handleChangeUnit = React.useCallback(
      (unitId: number | null) => () => {
        if (typeof onChangeUnit === 'function') {
          onChangeUnit(unitId);
          dispatch(
            filterConditionSlice.actions.setFilterCondition({
              conditions: !unitId || selfUser.nursingHomeUnitId ? 'all' : 'unit',
              unitId: selfUser.nursingHomeUnitId ? selfUser.nursingHomeUnitId : unitId,
            })
          );
          localStorage.setItem('condition', !unitId ? 'all' : 'unit');
          localStorage.setItem('unit', String(selfUser.nursingHomeUnitId ? selfUser.nursingHomeUnitId : unitId));
          handleClose();
        }
      },
      [onChangeUnit, selfUser]
    );

    React.useEffect(() => {
      if (!selfUser || !selfUnits || selfUser.hasUnitPerm) return;
      setUnits(selfUnits);
    }, [selfUser, selfUnits]);

    return (
      <div css={Container}>
        <div css={TitleContainer}>
          <div css={Title}>{title}</div>
          <StyledButton onClick={onUpdate} startIcon={<RefreshIcon color='primary' />}>
            {t('update', '更新')}
          </StyledButton>
        </div>
        <div css={RightElementContainer}>
          {rightElement && rightElement}

          <Button onClick={handleClick}>
            <span style={{ color: color.black }}>{t('filter', '絞り込み')}</span>
            <>{conditions === 'all' ? <ArrowDropDownIcon /> : <FilterAltIcon />}</>
          </Button>
          <StyledMenu open={open} anchorEl={anchorEl} onClose={handleClose}>
            <MenuItem onClick={handleChangeUnit(selfUser.nursingHomeUnitId ?? null)}>
              <ListItemIcon>{conditions === 'all' && <CheckIcon />}</ListItemIcon>
              <ListItemText>{t('all', 'すべて')}</ListItemText>
            </MenuItem>
            <MenuItem onClick={handleChangeDeviceOnly}>
              <ListItemIcon>{conditions === 'deviceOnly' && <CheckIcon />}</ListItemIcon>
              <ListItemText>{t('wearing', '装着中')}</ListItemText>
            </MenuItem>
            {!selfUser.hasUnitPerm && units && units.length > 0 && (
              <div>
                {units.map((unit) => (
                  <MenuItem key={unit.id} onClick={handleChangeUnit(unit.id)}>
                    <ListItemIcon>{conditions === 'unit' && unitId === unit.id && <CheckIcon />}</ListItemIcon>
                    <ListItemText>{unit.name}</ListItemText>
                  </MenuItem>
                ))}
              </div>
            )}
          </StyledMenu>
        </div>
      </div>
    );
  }
);
