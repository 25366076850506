import React from 'react';
import { css } from '@emotion/react';

interface Props {
  name: string;
  serialNumber?: string;
  unitName: string;
  roomName: string | null;
  memo?: string;
  rightElement: React.ReactNode;
}

const ResidentHeaderWrapper = css`
  width: 100%;
  min-height: 48px;
  padding: 8px 16px;
`;

const Container = css`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const Name = css`
  font-weight: bold;
  font-size: 18px;
`;

const LeftContainer = css`
  min-height: 48px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const RightContainer = css`
  display: flex;
  justify-content: end;
  flex-grow: 1;
`;

const MemoRow = css`
  font-size: 14px;
`;

const ResidentInfo = css`
  font-size: 14px;
`;

export const ResidentItemHeader = React.memo(({ name, serialNumber, unitName, memo, roomName, rightElement }: Props) => {
  return (
    <div css={ResidentHeaderWrapper}>
      <div css={Container}>
        <div css={LeftContainer}>
          <div css={Name}>{name} {serialNumber}</div>
          <div css={ResidentInfo}>
            {unitName}
            {roomName && unitName && ' - '}
            {roomName}
          </div>
        </div>
        <div css={RightContainer}>{rightElement}</div>
      </div>
      {memo && <div css={MemoRow}>{memo}</div>}
    </div>
  );
});
