import React from 'react';
import dayjs from 'dayjs';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { css } from '@emotion/react';
import CloseIcon from '@mui/icons-material/Close';
import { OutlineButton } from '../../../../components/outlineButton';
import { useTranslation } from 'react-i18next';

interface Props {
  id: string;
  graphOption: Highcharts.Options;
  range: { start: dayjs.Dayjs; end: dayjs.Dayjs };
  isFirst: boolean;
  isResampleView: boolean;
  onDelete: (id: string) => void;
}

const Container = css``;

const Header = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DetailGraphItem = React.memo(
  ({ id, graphOption, range, isFirst = false, isResampleView, onDelete }: Props) => {
    const chartRef = React.useRef<HighchartsReact.RefObject>(null);
    const { start, end } = range;
    const { t } = useTranslation();

    const handleDelete = React.useCallback(() => {
      onDelete(id);
    }, []);

    React.useEffect(() => {
      if (!chartRef.current) return;
      chartRef.current.chart.redraw();
    }, [isResampleView]);

    return (
      <div css={Container}>
        {!isFirst && (
          <div css={Header}>
            <div>
              {start.local().format('YYYY/MM/DD HH:mm:ss')} ~ {end.local().format('YYYY/MM/DD HH:mm:ss')}
            </div>
            <div>
              <OutlineButton icon={<CloseIcon />} onClick={handleDelete}>
                {t('removeGraph', 'グラフを非表示')}
              </OutlineButton>
            </div>
          </div>
        )}
        <HighchartsReact ref={chartRef} highcharts={Highcharts} options={graphOption} />
      </div>
    );
  }
);
