/* eslint-disable camelcase */

export const color = {
  white: '#ffffff',
  black: '#000000',
  dark_gray: '#444444',
  gray: '#888888',
  thin_gray: '#AAAAAA',
  ash_gray: '#c0c0c0',
  light_gray: '#f9f9f9',
  border_gray: '#cccccc',
  border_gray_dark: '#dddddd',
  bg_gray: '#e6eaf0',
  bg_light_green: '#E3F3F3',
  bg_light_gray: '#EDEDED',
  input_gray: '#d0d0d0',
  button_gray: '#e7e7e7',
  table_gray: '#ededed',
  light_blue: '#00CAFA ',
  green: '#0cb2b4',
  red: '#eb3223',
  alert_red: '#fadbda',
  light_green: '#e5f3f4',
  table_green: '#80cfd4',
  notification_red: '#EB3223',
  notification_red_bg: '#FFF0EF',
  notification_pink: '#FF8FA5',
  notification_pink_bg: '#FFEEF1',
  notification_yellow: '#FFBB00',
  notification_yellow_bg: '#FFF7DA',
  graph_purple: '#9B6DCA',
  graph_green: '#44CA15',
  light_pink: '#FFE9ED',
  light_black: '#323232',
  brown: '#AE8315',
  notification_navy: '#484C75',
  notification_navy_bg: '#DFE2FF',
};
