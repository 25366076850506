import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../core/store';

interface FilterCondition {
  conditions: 'all' | 'deviceOnly' | 'unit';
  unitId: number | null;
}

const initialState: FilterCondition = {
  conditions: 'all',
  unitId: null,
};

export const filterConditionSlice = createSlice({
  name: 'filterCondition',
  initialState,
  reducers: {
    setFilterCondition: (state: FilterCondition, action: PayloadAction<FilterCondition>) => {
      state.conditions = action.payload.conditions;
      state.unitId = action.payload.unitId;
    },
    reset: (state) => {
      state.conditions = 'all';
      state.unitId = null;
    },
  },
});

export const { setFilterCondition, reset } = filterConditionSlice.actions;

export const filterConditionSelector = (state: RootState) => state.filterCondition;
