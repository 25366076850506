import React from 'react';
import api from '../../../api';
import { IGetResidentCareRecordParams } from '../../../types/api/care-record/getCareRecord';
import { IGetResidentRes } from '@/src/types/api/resident/getResident';

export const useFetchResident = (
  shouldInitFetch: boolean = false, //初回fetchをするかどうか
  residentId: number | null = null
) => {
  const [resident, setResident] = React.useState<IGetResidentRes>();

  const fetchResident = async (residentId: number) => {
    const params: IGetResidentCareRecordParams = {
      residentId,
    };

    let result = await api.get('/residents/:residentId', {
      params,
    });

    setResident(result);
    return result;
  };

  React.useEffect(() => {
    if (!shouldInitFetch || !residentId) return;
    fetchResident(residentId);
  }, []);

  return { resident, fetchResident };
};
