import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { css } from '@emotion/react';
import { useHistory, useParams } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import api from '../../api';
import { useSnackbar } from '../../contexts/SnackbarContext';
import { ICreateCareRecordParams } from '../../types/api/care-record/createCareRecord';
import { IUpdateUrineVolumeReset } from '../../types/api/resident/updateUrineVolumeReset';
import { color } from '../../styles';
import { Dialog } from '../mui/dialog/Dialog';
import { CareRecordButtonGroup } from '../mui/radio/Radio';
import { DateSelector } from '../mui/dateSelector';
import { TimeSelector } from '../mui/timeSelector';
import { OutlineButton } from '../outlineButton';
import { IResident } from '../../types/api/traversal/getNursingHomeResidents';
import { CheckButton } from '../mui/checkButton';
import { RecordDialog } from './recordDialog';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import { numBetweenMinMax } from '../../utils/dataFormatUtil';

interface Props {
  resident: IResident;
  resetNotification: (residentId: number) => void;
  onClose: () => void;
  isDisplayPadUrineVolume?: boolean;
}

const AlignCenter = css`
  display: flex;
  align-items: center;
`;

const ContentGap = css`
  display: flex;
  gap: 16px;
`;

const W200 = css`
  width: 200px;
`;

const InheritedWidth = css`
  width: inherit;
`;

const MarginTop = css`
  margin-top: 8px;
`;

const MarginBottom = css`
  margin-bottom: 24px;
`;

const Container = css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const UpperContainer = css`
  display: flex;
  justify-content: space-evenly;
  min-width: 580px;
  width: 100%;
  gap: 8px;
`;

const ContentContainer = css`
  display: flex;
  width: 480px;
  margin-bottom: 16px;
`;

const FlexColumn = css`
  display: flex;
  flex-direction: column;
`;

const LabelContainer = css`
  display: flex;
  flex-direction: column;
  width: 60px;
  margin: 0 8px 0 0;
`;

const InnerRowContainer = css`
  height: 72px;
`;

const ContentTitle = css`
  width: 40px;
  writing-mode: vertical-rl;
  margin-top: 4px;
  margin-right: 24px;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.3em;
  border-right: 3px solid ${color.dark_gray};
`;

const HeaderLabel = css`
  margin-right: 8px;
  font-size: 16px;
  color: ${color.dark_gray};
  line-height: 1em;
  flex-shrink: 0;
`;

const TableLabel = css`
  font-size: 14px;
  color: ${color.dark_gray};
  line-height: 1em;
  flex-shrink: 0;
`;

const TableIcon = css`
  width: 30px;
  min-width: 30px;
  height: 30px;
  margin: 4px;
`;

const ResultModalContainer = css`
  margin: 0 auto;
`;

const ModalContentContainer = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0 24px;
`;

const ModalLargeIcon = css`
  width: 56px;
  min-width: 56px;
  height: 56px;
`;

const ModalText = css`
  font-size: 16px;
  p {
    margin: 0;
  }
`;

const ModalTitleText = css`
  font-size: 18px;
  font-weight: bold;
`;

const Unit = css`
  font-size: 14px;
  margin: 16px 8px 0 8px;
`;

const StyledButton = styled(Button)`
  &.MuiButton-outlined {
    background-color: ${color.white};
    color: ${color.dark_gray};
    border: 1px solid ${color.dark_gray};
  }
`;

export const RecordModal = React.memo(({ resident, resetNotification, onClose }: Props) => {
  const { nursingHomeId } = useParams<{ nursingHomeId: string }>();
  const now = dayjs();
  const history = useHistory();
  const { showSnackbar } = useSnackbar();
  const { t, i18n } = useTranslation();

  const [isDisplayAdvancedForm, setIsDisplayAdvancedForm] = React.useState<boolean>(false);
  const [isResetModalOpen, setResetModalOpen] = React.useState<boolean>(false);
  const [isSuccessModalOpen, setSuccessModalOpen] = React.useState<boolean>(false);
  const [isAdviceModalOpen, setAdviceModalOpen] = React.useState<boolean>(false);
  const [onSend, setOnSend] = React.useState<boolean>(false);
  const [isDisplayPadUrineVolume, setIsDisplayPadUrineVolume] = React.useState<boolean>(false);
  const [isDisplayToiletUrineVolume, setIsDisplayToiletUrineVolume] = React.useState<boolean>(false);

  // formData
  const [date, setDate] = React.useState<Dayjs>(now);
  const [isUrinatedToilet, setisUrinatedToilet] = React.useState<boolean | null>(null);
  const [padUrineVolumeType, setPadUrineVolumeType] = React.useState<string | null>(null);
  const [defecationVolumeType, setDefecationVolumeType] = React.useState<string | null>(null);
  const [hardnessType, setHardnessType] = React.useState<string | null>(null);
  const [isCheckPant, setCheckPant] = React.useState<boolean>(false);
  const [isCheckPad, setCheckPad] = React.useState<boolean>(false);
  const [toiletVolume, setToiletVolume] = React.useState<number | null>(null);
  const [padUrineVolume, setPadUrineVolume] = React.useState<number | null>(null);
  const [drinkingVolume, setDrinkingVolume] = React.useState<number | null>(null);
  const [staffName, setStaffName] = React.useState<string | null>(null);
  const [workingTime, setWorkingTime] = React.useState<number | null>(5);

  // formErrorControl
  const [isDateError, setDateError] = React.useState<boolean>(false);

  // formControl
  const isDisabledHardnessTypeForm = React.useMemo(() => {
    return !defecationVolumeType;
  }, [defecationVolumeType]);

  const isDisabledSubmit = React.useMemo(() => {
    if (onSend) return true;
    if (isUrinatedToilet === null && padUrineVolumeType === null) {
      return !defecationVolumeType && !isCheckPant && !isCheckPad && !drinkingVolume;
    } else {
      return isUrinatedToilet === null || padUrineVolumeType === null;
    }
  }, [isUrinatedToilet, padUrineVolumeType, defecationVolumeType, isCheckPant, isCheckPad, drinkingVolume, onSend]);

  const createCareRecord = async (data: ICreateCareRecordParams) => {
    return await api.post('/residents/:residentId/care-records', data);
  };

  // api
  const updateUrineVolumeReset = async (residentId: number) => {
    const data: IUpdateUrineVolumeReset = {
      residentId,
      urineVolumeResetFlg: true,
    };
    return await api.put('/residents/:residentId/urine-volume-reset', data);
  };

  const handleChangeDate = (date: Dayjs) => {
    setDate(date);
    setDateError(false);
  };

  const handleChangeTime = (time: Dayjs) => {
    setDate(time);
    setDateError(false);
  };

  const handleChangeRecordButton = () => {
    setIsDisplayAdvancedForm((prev) => !prev);
    setisUrinatedToilet(null);
    setPadUrineVolumeType(null);
    setDefecationVolumeType(null);
    setHardnessType(null);
    setCheckPant(false);
    setCheckPad(false);
    setIsDisplayPadUrineVolume(false);
    setIsDisplayToiletUrineVolume(false);
    setToiletVolume(null);
    setPadUrineVolume(null);
    setDrinkingVolume(null);
  };

  const handleChangeIsUrinatedToilet = (data: string | number | boolean | null) => {
    setisUrinatedToilet(data === 'true' ? true : data === 'false' ? false : null);

    // 無しが選択された場合は入力値をクリアする
    if (data == 'false') setToiletVolume(null);
    setIsDisplayToiletUrineVolume(data === 'true' ? true : false);
  };

  const handleChangePadUrineVolumeType = (data: string | number | boolean | null) => {
    // 有は簡単記録のおむつパッド尿量は'中'として記録する
    setPadUrineVolumeType(data ? String(data) : null);

    // 無しが選択された場合は入力値をクリアする
    if (data == 'none') setPadUrineVolume(null);
    setIsDisplayPadUrineVolume(data !== 'none' ? true : false);
  };

  const handleChangeDefecationVolumeType = (data: string | number | boolean | null) => {
    if (!data) setHardnessType(null);
    setDefecationVolumeType(data ? String(data) : null);
  };

  const handleChangeHardnessType = (data: string | number | boolean | null) => {
    setHardnessType(data ? String(data) : null);
  };

  const handleClickPantButton = () => {
    setCheckPant(!isCheckPant);
  };

  const handleClickPadButton = () => {
    setCheckPad(!isCheckPad);
  };

  const handleChangeToiletVolume = (e: React.ChangeEvent<HTMLInputElement>) => {
    setToiletVolume(numBetweenMinMax(+e.target.value, 0, 9999));
  };

  const handleChangePadUrineVolume = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPadUrineVolume(numBetweenMinMax(+e.target.value, 0, 9999));
  };

  const handleChangeDrinkingVolume = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDrinkingVolume(numBetweenMinMax(+e.target.value, 0, 9999));
  };

  const handleChangeStaffName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStaffName(e.target.value);
  };

  const handleChangeWorkingTime = (e: React.ChangeEvent<HTMLInputElement>) => {
    setWorkingTime(numBetweenMinMax(+e.target.value, 0, 9999));
  };

  const handleSubmit = () => {
    setOnSend(true);
    const sendData = {
      residentId: resident.id,
      timestamp: date.local().utc().format(),
      urinatedToilet: isUrinatedToilet,
      toiletType: isUrinatedToilet === null && padUrineVolumeType === null ? null : 'toilet',
      padUrineVolumeType,
      padType: isUrinatedToilet === null && padUrineVolumeType === null ? null : 'pad',
      defecationVolumeType,
      hardnessType,
      toiletVolume,
      padUrineVolume,
      drinkingVolume,
      staffName,
      workingTime,
      diaper: {
        useDefaultDiaper: isCheckPant ? true : null,
        useDefaultPad: isCheckPad ? true : null,
      },
    };

    createCareRecord(sendData)
      .then((res) => {
        // トイレかおむつ・パッドの記録が有る場合は排泄されたとみなしグラフを落として、通知カバーも除去する
        if (sendData.urinatedToilet || (sendData.padUrineVolumeType && sendData.padUrineVolumeType !== 'none')) {
          updateUrineVolumeReset(resident.id).catch((err) => {
            console.log(err);
          });
          setTimeout(() => {
            resetNotification(resident.id);
          }, 500);
        }
        // トイレで排泄し、おむつ・パッドで排泄がなかった場合は成功とする。それ以外はアドバイス画面へ
        if (sendData.urinatedToilet && sendData.padUrineVolumeType == 'none') {
          openSuccessModal();
        } else if (sendData.urinatedToilet !== null && sendData.padUrineVolumeType !== null) {
          openAdviceModal();
        } else {
          showSnackbar(t('recordSaved', '記録しました'), 'success');
          onClose();
        }
        setOnSend(false);
      })
      .catch((err) => {
        console.log(err);
        setOnSend(false);
        showSnackbar(t('recordingFailedPleaseTryAgain', '記録に失敗しました 再度お試しください'));
      });
  };

  const openResetModal = () => {
    setResetModalOpen(true);
  };

  const onResetModalClose = () => {
    setResetModalOpen(false);
  };

  const onResetModalSubmit = () => {
    updateUrineVolumeReset(resident.id)
      .then((res) => {
        // TODO: Can't perform a React state update on an unmounted component.エラーがでる
        resetNotification(resident.id);
        onClose();
        setResetModalOpen(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const openSuccessModal = () => {
    setSuccessModalOpen(true);
  };

  const onSuccessModalClose = () => {
    onClose();
    setSuccessModalOpen(false);
  };

  const openAdviceModal = () => {
    setAdviceModalOpen(true);
  };

  const onAdviceModalClose = () => {
    onClose();
    setSuccessModalOpen(false);
  };

  const handleClickLineChangeButton = () => {
    history.push(`/nursinghome/${nursingHomeId}/residents/${resident.id}/edit`);
  };

  const handleClickTroubleButton = () => {
    onClose();
    setAdviceModalOpen(false);
    i18n.language === 'ja'
      ? window.open('https://dfree.biz/img/pdf/professional_solution.pdf', '_blank')
      : window.open('https://www.dfreeus.biz/support', '_blank');
  };

  return (
    <>
      <div css={Container}>
        <div css={[UpperContainer, AlignCenter, MarginBottom]}>
          <div css={[W200, AlignCenter]}>
            <div css={HeaderLabel}>{t('date', '日付 : ')}</div>
            <DateSelector
              newValue={date}
              label={''}
              minDate={now.subtract(1, 'years')}
              maxDate={now}
              variant={'outlined'}
              onChange={handleChangeDate}
            />
          </div>
          <div css={[W200, AlignCenter]}>
            <div css={HeaderLabel}>{t('excretionTimeLabel', '排泄時刻 : ')}</div>
            <TimeSelector newValue={date} label={''} variant={'outlined'} onChange={handleChangeTime} />
          </div>
          <OutlineButton
            onClick={handleChangeRecordButton}
            icon={<img src='/images/residents/recordModal/change.svg' alt='' />}
          >
            {isDisplayAdvancedForm ? t('basicRecord', '簡単記録') : t('detailedRecord', '詳細記録')}
          </OutlineButton>
        </div>
        {isDisplayAdvancedForm ? (
          <>
            <div css={[ContentContainer]}>
              <div css={[AlignCenter, ContentTitle]}>{t('urine', '排尿')}</div>
              <div css={InheritedWidth}>
                <div css={[AlignCenter, InnerRowContainer]}>
                  <div css={[LabelContainer, AlignCenter]}>
                    <img css={TableIcon} src='/images/residents/recordModal/toilet.svg' alt='' />
                    <span css={TableLabel}>{t('toilet', 'トイレ')}</span>
                  </div>
                  <CareRecordButtonGroup
                    data={[
                      { key: true, value: t('yes', '有') },
                      { key: false, value: t('no', '無') },
                    ]}
                    onChange={handleChangeIsUrinatedToilet}
                  />
                </div>
                {isDisplayToiletUrineVolume && (
                  <div css={[AlignCenter, InnerRowContainer]}>
                    <TextField
                      id={'toilet-volume-field'}
                      label={t('toilet', 'トイレ') + ' ' + t('urineAmount', '排尿量')}
                      type={'number'}
                      value={toiletVolume ?? ''}
                      onChange={handleChangeToiletVolume}
                      fullWidth={true}
                    />
                    <span css={Unit}>ml</span>
                  </div>
                )}
                <div css={[AlignCenter, InnerRowContainer]}>
                  <div css={[LabelContainer, AlignCenter]}>
                    <img css={TableIcon} src='/images/residents/recordModal/pad.svg' alt='' />
                    <div css={TableLabel}>
                      <span>
                        {t('diaper', 'おむつ')}
                        <br />
                        {t('pad', 'パッド')}
                      </span>
                    </div>
                  </div>
                  <CareRecordButtonGroup
                    data={[
                      { key: 'large', value: t('large', '多'), icon: '/images/recordButtonIcon/urine-large.svg' },
                      { key: 'medium', value: t('medium', '中'), icon: '/images/recordButtonIcon/urine-medium.svg' },
                      { key: 'small', value: t('small', '少'), icon: '/images/recordButtonIcon/urine-small.svg' },
                      { key: 'none', value: t('none', '無'), icon: '/images/recordButtonIcon/urine-none.svg' },
                    ]}
                    onChange={handleChangePadUrineVolumeType}
                  />
                </div>
                {isDisplayPadUrineVolume && (
                  <div css={[AlignCenter, InnerRowContainer]}>
                    <TextField
                      id={'pad-volume-field'}
                      label={t('pad', 'パッド') + ' ' + t('urineAmount', '排尿量')}
                      type={'number'}
                      value={padUrineVolume ?? ''}
                      onChange={handleChangePadUrineVolume}
                      fullWidth={true}
                    />
                    <span css={Unit}>ml</span>
                  </div>
                )}
              </div>
            </div>
            <div css={[ContentContainer]}>
              <div css={[AlignCenter, ContentTitle]}>{t('stool', '排便')}</div>
              <div css={InheritedWidth}>
                <div css={[AlignCenter, InnerRowContainer]}>
                  <CareRecordButtonGroup
                    data={[
                      { key: 'large', value: t('large', '多'), icon: '/images/recordButtonIcon/stool-large.svg' },
                      { key: 'medium', value: t('medium', '中'), icon: '/images/recordButtonIcon/stool-medium.svg' },
                      { key: 'small', value: t('small', '少'), icon: '/images/recordButtonIcon/stool-small.svg' },
                      { key: 'bit', value: t('bit', '付'), icon: '/images/recordButtonIcon/stool-bit.svg' },
                    ]}
                    onChange={handleChangeDefecationVolumeType}
                  />
                </div>
                <div css={[AlignCenter, InnerRowContainer]}>
                  <CareRecordButtonGroup
                    data={[
                      { key: 'hard', value: t('hard', '硬'), icon: '/images/recordButtonIcon/stool-hard.svg' },
                      { key: 'middle', value: t('normal', '普'), icon: '/images/recordButtonIcon/stool-normal.svg' },
                      { key: 'soft', value: t('soft', '軟'), icon: '/images/recordButtonIcon/stool-soft.svg' },
                      { key: 'littleSoft', value: t('mushy', '泥'), icon: '/images/recordButtonIcon/stool-mushy.svg' },
                      {
                        key: 'water',
                        value: t('waterly', '水'),
                        icon: '/images/recordButtonIcon/stool-waterly.svg',
                      },
                    ]}
                    newValue={hardnessType}
                    isDisabled={isDisabledHardnessTypeForm}
                    onChange={handleChangeHardnessType}
                  />
                </div>
              </div>
            </div>
            <div css={[ContentContainer]}>
              <div css={[AlignCenter, ContentTitle]}>{t('use', '交換')}</div>
              <div css={InheritedWidth}>
                <div css={[AlignCenter, InnerRowContainer]} style={{ gap: '8px' }}>
                  <CheckButton onClick={handleClickPantButton}>{t('diaperPants', 'テープ/パンツ')}</CheckButton>
                  <CheckButton onClick={handleClickPadButton}>{t('pad', 'パッド')}</CheckButton>
                </div>
              </div>
            </div>
            <div css={[ContentContainer]}>
              <div css={[AlignCenter, ContentTitle]}>{t('hydration', '飲水')}</div>
              <div css={[AlignCenter, InnerRowContainer, InheritedWidth]}>
                <TextField
                  id={'drink-volume-field'}
                  label={t('drinkingVolume', '飲水量')}
                  type={'number'}
                  value={drinkingVolume ?? ''}
                  onChange={handleChangeDrinkingVolume}
                  fullWidth={true}
                />
                <span css={Unit}>ml</span>
              </div>
            </div>
            <div css={[ContentContainer]}>
              <div css={[AlignCenter, ContentTitle]}>{t('workRecord', '作業記録')}</div>
              <div css={[FlexColumn, MarginTop, InheritedWidth]}>
                <TextField
                  css={InnerRowContainer}
                  id={'staffname-field'}
                  label={t('staffName', '担当職員')}
                  value={staffName ?? ''}
                  onChange={handleChangeStaffName}
                  fullWidth={true}
                />
                <div css={AlignCenter}>
                  <TextField
                    css={InnerRowContainer}
                    id={'workingtime-field'}
                    label={t('workingTime', 'ケア時間')}
                    value={workingTime ?? ''}
                    type={'number'}
                    onChange={handleChangeWorkingTime}
                    fullWidth={true}
                  />
                  <span css={Unit}>{t('minutes', '分')}</span>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div css={[ContentContainer]}>
            <div css={[AlignCenter, ContentTitle]}>{t('urine', '排尿')}</div>
            <div css={InheritedWidth}>
              <div css={[AlignCenter, InnerRowContainer]}>
                <div css={[LabelContainer, AlignCenter]}>
                  <img css={TableIcon} src='/images/residents/recordModal/toilet.svg' alt='' />
                  <span css={TableLabel}>{t('toilet', 'トイレ')}</span>
                </div>
                <CareRecordButtonGroup
                  data={[
                    { key: true, value: t('yes', '有') },
                    { key: false, value: t('no', '無') },
                  ]}
                  onChange={handleChangeIsUrinatedToilet}
                />
              </div>
              <div css={[AlignCenter, InnerRowContainer]}>
                <div css={[LabelContainer, AlignCenter]}>
                  <img css={TableIcon} src='/images/residents/recordModal/pad.svg' alt='' />
                  <div css={TableLabel}>
                    <span>
                      {t('diaper', 'おむつ')}
                      <br />
                      {t('pad', 'パッド')}
                    </span>
                  </div>
                </div>
                <CareRecordButtonGroup
                  data={[
                    { key: 'medium', value: t('yes', '有') },
                    { key: 'none', value: t('no', '無') },
                  ]}
                  onChange={handleChangePadUrineVolumeType}
                />
              </div>
            </div>
          </div>
        )}
        <div css={[FlexColumn, ContentGap]}>
          <div css={ContentGap}>
            <Button variant='contained' disableElevation color='secondary' onClick={onClose}>
              {t('cancel', 'キャンセル')}
            </Button>
            <Button variant='contained' disabled={isDisabledSubmit} disableElevation onClick={handleSubmit}>
              {t('record', '記録する')}
            </Button>
          </div>
          <div>
            <StyledButton variant='outlined' disableElevation onClick={openResetModal}>
              {t('resetTo0WoRecording', '記録せずに0にする')}
            </StyledButton>
          </div>
        </div>
      </div>
      {isResetModalOpen && (
        <Dialog
          isOpen={isResetModalOpen}
          title={t('wouldYouLikeToResetTo0WoRecording', '記録をせずに0にしますか?')}
          onClose={onResetModalClose}
          onOkClick={onResetModalSubmit}
        >
          <div css={[MarginBottom, ModalText]}>{resident.name}</div>
        </Dialog>
      )}
      {isSuccessModalOpen && (
        <RecordDialog isOpen={isSuccessModalOpen} dialogWidth={'620px'} onClose={onSuccessModalClose}>
          <div css={ResultModalContainer}>
            <img
              css={[MarginBottom, ModalLargeIcon]}
              src='/images/residents/recordModal/record_success.svg'
              alt='トイレ誘導成功'
            />
            <div css={[MarginBottom, ModalTitleText]}>
              {t('goodJobProvidingATimelyAssistance', 'トイレ誘導成功おめでとうございます 👏')}
            </div>
            <div css={[MarginBottom, ModalText]}>
              <p>
                {t(
                  'pleaseContinueToUseDFreeForToiletAssistance',
                  '引き続き、トイレで排尿できるようDFreeをご活用ください'
                )}
              </p>
            </div>
            <Button variant='contained' disableElevation color='secondary' onClick={onSuccessModalClose}>
              {t('close', '閉じる')}
            </Button>
          </div>
        </RecordDialog>
      )}
      {isAdviceModalOpen && (
        <RecordDialog isOpen={isAdviceModalOpen} dialogWidth={'620px'} onClose={onAdviceModalClose}>
          <div>
            <div css={ResultModalContainer}>
              <img
                css={[MarginBottom, ModalLargeIcon]}
                src='/images/residents/adviceModal/icon_advice.svg'
                alt='排尿記録完了'
              />
              <div css={[MarginBottom, ModalTitleText]}>
                {t('pleaseContinueToMaintainUrinationRecord', '排尿記録の入力ありがとうございます 👏')}
              </div>
            </div>
            <div css={[MarginBottom, ModalText]}>
              <p>
                {t(
                  'ifYouAreHavingTroubleWithNotificationsModeSettingsOrWithMeasurementsPleaseCheckFAQ',
                  '通知やモード設定、数値に違和感がある場合などお困りのときは、「困った時の対処法」をご確認ください'
                )}
              </p>
            </div>
            <div css={[FlexColumn, ContentGap]}>
              <div css={ModalContentContainer}>
                <Button variant='outlined' disableElevation onClick={handleClickLineChangeButton}>
                  {t('changeNotificationModeSettings', '通知・モード設定の変更')}
                </Button>
                <Button variant='contained' disableElevation onClick={handleClickTroubleButton}>
                  {t('fAQ', '困った時の対処法')}
                </Button>
              </div>
              <div>
                <Button variant='contained' color='secondary' disableElevation onClick={onAdviceModalClose}>
                  {t('close', '閉じる')}
                </Button>
              </div>
            </div>
          </div>
        </RecordDialog>
      )}
    </>
  );
});
