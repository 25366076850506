import React from "react";
import { css } from "@emotion/react";
import { SIZE } from '../../../constants';
import { color } from '../../../styles';
import { Select } from "../../../components/mui/select";
import { Diaper } from "@/src/types/api/order/getOrders";
import { ExpendableOrderCellWidth, OrderCellWidth } from './OrderTable';

interface Props {
  diaper: Diaper;
  isConfirm: boolean;
  isExpendable?: boolean;
  amounts: { key: number; value: string }[];
  onAmountChange: (diaper: Diaper, amount: number, type: 'inner' | 'outer' | 'expendable') => void;
}

const Column = css`
  flex-direction: column;
`;

const ColumnItem = css`
  border-right: none !important;
`;

const TableContentsItem = css`
  color: #444444;
  font-size: 14px;
  height: 86px;
  border-bottom: none;

  .MuiOutlinedInput-root {
    margin: 4px 16px;
  }
`;

const LeftItem = css`
  margin-left: 24px;
`;

const ConfirmAmount = css`
  color: ${color.red};
`;

const GrayColor = css`
  color: ${color.thin_gray};
`;

const ExpendableName = css`
  flex-direction: column;
  align-items: baseline !important;
  justify-content: center;
`;

const TextLeft = css`
  text-align: left;
  width: 100%;
  justify-content: start !important;
`;

export const OrderTableContents: React.FC<Props> = React.memo(
  ({ diaper, amounts, isConfirm, isExpendable, onAmountChange }) => {
    const [amount, setAmount] = React.useState(0);

    const handleChangeAmount = (amount: number) => {
      setAmount(amount);
      onAmountChange(diaper, amount, diaper.diaperShape);
    };

    return (
      <>
        {isExpendable ? (
          <>
            <div css={[TableContentsItem, LeftItem, Column, ExpendableName, ExpendableOrderCellWidth[0]]}>
              <div css={[ColumnItem]}>{diaper.diaperSeries}</div>
              <div css={[ColumnItem]}>{diaper.diaperName}</div>
            </div>
            <div css={[TableContentsItem, Column, ExpendableOrderCellWidth[1]]}>
              <div>
                {!isConfirm ? (
                  <Select
                    listData={amounts}
                    value={{
                      key: diaper.amount ?? 0,
                      value: `${diaper.amount ?? 0}個`,
                    }}
                    onChange={(amount) => handleChangeAmount(+amount.key)}
                  />
                ) : (
                  <div css={ConfirmAmount}>{diaper.amount}個</div>
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            <div css={[TableContentsItem, LeftItem, Column, OrderCellWidth[0]]}>
              <div css={[ColumnItem, TextLeft]}>{diaper.diaperSeries}</div>
              <div css={[ColumnItem, TextLeft]}>{diaper.diaperName}</div>
              <div css={[ColumnItem, TextLeft, GrayColor]}>ケース入り枚数: {diaper.diaperBags}袋</div>
              <div css={[ColumnItem, TextLeft, GrayColor]}>袋入り枚数: {diaper.diaperSheets}枚</div>
            </div>
            <div css={[TableContentsItem, OrderCellWidth[1]]}>{SIZE[diaper.diaperSize] ?? '-'}</div>
            <div css={[TableContentsItem, Column, OrderCellWidth[2]]}>
              {diaper.bagStock || diaper.bagStock === 0 || diaper.stock || diaper.stock === 0 ? (
                <>
                  <span css={ColumnItem}>
                    {diaper.stock ? diaper.stock + 'ケース' : '0ケース'}{' '}
                    {diaper.bagStock ? diaper.bagStock + '袋' : '0袋'}
                  </span>
                  <span css={[ColumnItem, GrayColor]}>{diaper.stockUpdatedAt + '更新'}</span>
                </>
              ) : (
                <span css={ColumnItem}>在庫情報なし</span>
              )}
            </div>
            <div css={[TableContentsItem, Column, OrderCellWidth[3]]}>
              <div>
                {!isConfirm ? (
                  <Select
                    listData={amounts}
                    value={{
                      key: diaper.amount ?? 0,
                      value: `${diaper.amount ?? 0}ケース`,
                    }}
                    onChange={(amount) => handleChangeAmount(+amount.key)}
                  />
                ) : (
                  <div css={ConfirmAmount}>{diaper.amount}ケース</div>
                )}
              </div>
            </div>
            <div css={[TableContentsItem, OrderCellWidth[4]]}>
              {diaper.diaperBags && diaper.amount ? diaper.diaperBags * diaper.amount + '袋' : ''}
            </div>
          </>
        )}
      </>
    );
  }
);
