import React from 'react';
import api from '../../../api';
import { IGetLifeResidentsRes, IGetLifeResidentsParams } from '@/src/types/api/life/getLifeResident';

export const useFetchLifeResidents = (
  shouldInitFetch: boolean = false, //初回fetchをするかどうか
  nursingHomeId: number | null = null,
  residentIds: number[] | null = null,
  from: string | null = null,
  to: string | null = null
) => {
  const [lifeResidents, setLifeResidents] = React.useState<IGetLifeResidentsRes>();

  const fetchLifeResidents = React.useCallback(
    async (
      nursingHomeId: number,
      residentIds: number[] | null = null,
      from: string | null = null,
      to: string | null = null
    ) => {
      const params: IGetLifeResidentsParams = {
        nursingHomeId,
        // eslint-disable-next-line camelcase
        resident_ids: residentIds,
        from,
        to,
      };

      if (!from) delete params.from;
      if (!to) delete params.to;

      let result = await api.get('/external/life/nursing-homes/:nursingHomeId/residents', {
        params,
      });
      setLifeResidents(result);
      return result;
    },
    [nursingHomeId, residentIds, from, to]
  );

  React.useEffect(() => {
    if (!shouldInitFetch || !nursingHomeId) return;
    fetchLifeResidents(nursingHomeId, residentIds, from, to);
  }, []);

  return { lifeResidents, fetchLifeResidents };
};
