import React from 'react';
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { Controller, Control, FieldErrorsImpl, DeepRequired } from 'react-hook-form';
import { TextField } from '@mui/material';
import { Select } from '../../../../components/mui/select';
import { ILifeResidentForm, LIFE_CARE_LEVEL, LIFE_DEMENTIA, LIFE_IMPAIRED } from '../../../../constants';
import { IGetResidentRes } from '../../../../types/api/resident/getResident';

interface Props {
  resident?: IGetResidentRes;
  control: Control<ILifeResidentForm, object>;
  errors: FieldErrorsImpl<DeepRequired<ILifeResidentForm>>;
}

const Container = css`
  padding: 40px 100px 0;
`;

const FormTextHeight = css`
  height: 56px;
`;

const FormTextItem = css`
  font-size: 16px;
  margin-left: 8px;
`;

const FormItem = css`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;
`;

const Label = css`
  flex: 0 0 30%;
`;

const Required = css`
  color: #eb3223;
`;

const FormContent = css`
  width: -webkit-fill-available;
`;

export const LifeSettingForm = ({ resident, control, errors }: Props) => {
  React.useEffect(() => {
    console.log(resident);
  }, [resident]);
  return (
    <div css={Container}>
      <div css={[FormItem, FormTextHeight]}>
        <label css={Label}>{'利用者氏名'}</label>
        <div css={[FormContent, FormTextItem]}>
          {resident?.lastName} {resident?.name}
        </div>
      </div>
      <div css={FormItem}>
        <label css={Label}>{'サービス種類コード: 2桁'}</label>
        <div css={FormContent}>
          <Controller
            name='serviceCode'
            control={control}
            rules={{
              pattern: { value: /^.{2}$/, message: '2文字で入力してください' },
            }}
            render={({ field: { value, onChange } }) => (
              <TextField
                value={value}
                label='サービス種類コード: 2桁'
                error={!!errors.serviceCode}
                onChange={onChange}
                helperText={errors.serviceCode?.message}
                fullWidth
              />
            )}
          />
        </div>
      </div>
      <div css={FormItem}>
        <label css={Label}>
          {'保険者番号(半角数字): 6桁'}
          <span css={Required}>*</span>
        </label>
        <div css={FormContent}>
          <Controller
            name='insurerNo'
            control={control}
            rules={{
              required: '保険者番号は必須です。',
              pattern: { value: /^\d{6}$/, message: '6文字の半角数字で入力してください' },
            }}
            render={({ field: { value, onChange } }) => (
              <TextField
                value={value}
                label='保険者番号(半角数字): 6桁'
                error={!!errors.insurerNo}
                onChange={onChange}
                helperText={errors.insurerNo?.message}
                fullWidth
              />
            )}
          />
        </div>
      </div>
      <div css={FormItem}>
        <label css={Label}>
          {'被保険者番号'}
          <br />
          {'(チェックディジットは不要): 10桁'}
          <span css={Required}>*</span>
        </label>
        <div css={FormContent}>
          <Controller
            name='insuredNo'
            control={control}
            rules={{
              required: '被保険者番号は必須です。',
              pattern: { value: /^\d{10}$/, message: '10文字で入力してください' },
            }}
            render={({ field: { value, onChange } }) => (
              <TextField
                value={value}
                label='被保険者番号(チェックディジットは不要): 10桁'
                error={!!errors.insuredNo}
                onChange={onChange}
                helperText={errors.insuredNo?.message}
                fullWidth
              />
            )}
          />
        </div>
      </div>
      <div css={FormItem}>
        <label css={Label}>
          {'利用者(姓)半角カナ: 30文字以内'}
          <span css={Required}>*</span>
        </label>
        <div css={FormContent}>
          <Controller
            name='lastFurigana'
            control={control}
            rules={{
              pattern: { value: /^.{0,30}$/, message: '30文字以内で入力してください' },
            }}
            render={({ field: { value, onChange } }) => (
              <TextField
                value={value}
                label='利用者(姓)半角カナ: 30文字以内'
                error={!!errors.lastFurigana}
                onChange={onChange}
                helperText={errors.lastFurigana?.message}
                fullWidth
              />
            )}
          />
        </div>
      </div>
      <div css={FormItem}>
        <label css={Label}>
          {'利用者(名)半角カナ: 30文字以内'}
          <span css={Required}>*</span>
        </label>
        <div css={FormContent}>
          <Controller
            name='firstFurigana'
            control={control}
            rules={{
              pattern: { value: /^.{0,30}$/, message: '30文字以内で入力してください' },
            }}
            render={({ field: { value, onChange } }) => (
              <TextField
                value={value}
                label='利用者(名)半角カナ: 30文字以内'
                error={!!errors.firstFurigana}
                onChange={onChange}
                helperText={errors.firstFurigana?.message}
                fullWidth
              />
            )}
          />
        </div>
      </div>
      <div css={FormItem}>
        <label css={Label}>
          {'利用者生年月日'}
          <span css={Required}>*</span>
        </label>
        <div css={FormContent}>
          <Controller
            name='birthday'
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => (
              <DatePicker
                views={['year', 'month', 'day']}
                label={'利用者生年月日'}
                value={dayjs(value)}
                onChange={(v) => onChange(v?.format('YYYY-MM-DD[T]HH:mm:ss[Z]'))}
                renderInput={(params) => <TextField variant='outlined' {...params} helperText={null} />}
              />
            )}
          />
        </div>
      </div>
      <div css={FormItem}>
        <label css={Label}>{'認定日'}</label>
        <div css={FormContent}>
          <Controller
            name='certifiedDate'
            control={control}
            render={({ field: { value, onChange } }) => (
              <DatePicker
                views={['year', 'month', 'day']}
                label={'認定日'}
                value={dayjs(value)}
                onChange={(v) => onChange(v?.format('YYYY-MM-DD'))}
                renderInput={(params) => <TextField variant='outlined' {...params} helperText={null} />}
              />
            )}
          />
        </div>
      </div>
      <div css={FormItem}>
        <label css={Label}>{'利用者介護認定年月日'}</label>
        <Controller
          name='carePeriodStart'
          control={control}
          render={({ field: { value, onChange } }) => (
            <DatePicker
              views={['year', 'month', 'day']}
              label={'利用者介護認定年月日(開始)'}
              value={dayjs(value)}
              onChange={(v) => onChange(v?.format('YYYY-MM-DD'))}
              renderInput={(params) => <TextField variant='outlined' {...params} helperText={null} />}
            />
          )}
        />
        <span>{'〜'}</span>
        <Controller
          name='carePeriodEnd'
          control={control}
          render={({ field: { value, onChange } }) => (
            <DatePicker
              views={['year', 'month', 'day']}
              label={'利用者介護認定年月日(終了)'}
              value={dayjs(value)}
              onChange={(v) => onChange(v?.format('YYYY-MM-DD'))}
              renderInput={(params) => <TextField variant='outlined' {...params} helperText={null} />}
            />
          )}
        />
      </div>
      <div css={FormItem}>
        <label css={Label}>
          {'要介護度'}
          <span css={Required}>*</span>
        </label>
        <div css={FormContent}>
          <Controller
            name='lifeCareLevel'
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => (
              <Select
                id='lifecare-level-selector'
                listData={LIFE_CARE_LEVEL}
                value={value}
                label={'要介護度'}
                fullWidth
                onChange={(v) => onChange(v.key)}
              />
            )}
          />
        </div>
      </div>
      <div css={FormItem}>
        <label css={Label}>{'障害高齢者の日常生活自立度'}</label>
        <div css={FormContent}>
          <Controller
            name='impairedElderlyIndependenceDegree'
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
                id='impaired-elderly-independence-degree-selector'
                listData={LIFE_IMPAIRED}
                value={value}
                label={'障害高齢者の日常生活自立度'}
                fullWidth
                onChange={(v) => onChange(v.key)}
              />
            )}
          />
        </div>
      </div>
      <div css={FormItem}>
        <label css={Label}>{'認知症高齢者の日常生活自立度'}</label>
        <div css={FormContent}>
          <Controller
            name='dementiaElderlyIndependenceDegree'
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
                id='dementia-elderly-independence-degree-selector'
                listData={LIFE_DEMENTIA}
                value={value}
                label={'認知症高齢者の日常生活自立度'}
                fullWidth
                onChange={(v) => onChange(v.key)}
              />
            )}
          />
        </div>
      </div>
      <div css={FormItem}>
        <label css={Label}>{'利用開始日・終了日'}</label>
        <Controller
          name='startDate'
          control={control}
          render={({ field: { value, onChange } }) => (
            <DatePicker
              views={['year', 'month', 'day']}
              label={'利用開始日(入所日)'}
              value={dayjs(value)}
              onChange={(v) => onChange(v?.format('YYYY-MM-DD'))}
              renderInput={(params) => <TextField variant='outlined' {...params} helperText={null} />}
            />
          )}
        />
        <span>{'〜'}</span>
        <Controller
          name='endDate'
          control={control}
          render={({ field: { value, onChange } }) => (
            <DatePicker
              views={['year', 'month', 'day']}
              label={'利用終了日(退所日)'}
              value={dayjs(value)}
              onChange={(v) => onChange(v?.format('YYYY-MM-DD'))}
              renderInput={(params) => <TextField variant='outlined' {...params} helperText={null} />}
            />
          )}
        />
      </div>
      <div css={FormItem}>
        <label css={Label}>{'死亡日'}</label>
        <div css={FormContent}>
          <Controller
            name='deathDate'
            control={control}
            render={({ field: { value, onChange } }) => (
              <DatePicker
                views={['year', 'month', 'day']}
                label={'死亡日'}
                value={dayjs(value)}
                onChange={(v) => onChange(v?.format('YYYY-MM-DD'))}
                renderInput={(params) => <TextField variant='outlined' {...params} helperText={null} />}
              />
            )}
          />
        </div>
      </div>
      <div css={FormItem}>
        <label css={Label}>{'メモ: 200文字以内'}</label>
        <div css={FormContent}>
          <Controller
            name='remarks'
            control={control}
            rules={{
              pattern: {
                value: /^.{0,200}$/,
                message: '200文字以内で入力してください',
              },
            }}
            render={({ field: { value, onChange } }) => (
              <TextField
                value={value}
                label='メモ: 200文字以内'
                error={!!errors.remarks}
                onChange={onChange}
                helperText={errors.remarks?.message}
                rows={3}
                fullWidth
                multiline
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};
