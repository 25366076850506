import React from "react";
import { useParams } from "react-router-dom";
import { css } from "@emotion/react";
import { useSelector } from "react-redux";
import { ResidentListHeader } from '../../../components/residentListHeader';
import api from '../../../api';
import { IGetNotificationRes } from '@/src/types/api/notification/getNotification';
import { NotificationListItem } from './notificationListItem';
import { selfDataSelector } from '../../../redux/config/selfData';
import { filterConditionSelector } from '../../../redux/config/filterCondition';
import { useLoading } from '../../../contexts/LoadingContext';
import { useTranslation } from 'react-i18next';

interface ResidentNotification extends IGetNotificationRes {
  residentName: string;
  residentLastName: string;
}

const NotificationListContainer = css`
  border-radius: 8px;
  overflow: hidden;
`;

const Container = css`
  padding: 0 16px 16px;
`;

const EmptyResidentContainer = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  font-size: 16px;
`;

export const Notification: React.VFC = () => {
  const { nursingHomeId } = useParams<{ nursingHomeId: string }>();
  const { selfUser } = useSelector(selfDataSelector);
  const { conditions, unitId } = useSelector(filterConditionSelector);
  const { showLoading, hideLoading } = useLoading();
  const { t } = useTranslation();

  const [residentNotifications, setResidentNotifications] = React.useState<ResidentNotification[]>([]);

  const handleUpdateNotifications = React.useCallback(() => {
    fetchNotifications(unitId, conditions === 'deviceOnly');
  }, [unitId, conditions]);

  const handleChangeNotifications = React.useCallback((unitId: number | null, isDeviceOnly = false) => {
    fetchNotifications(unitId, isDeviceOnly);
  }, []);

  const fetchNotifications = React.useCallback(
    async (unitId?: number | null, isDeviceOnly = false) => {
      showLoading();
      const notifications = await api.get('/nursing-homes/:nursingHomeId/notifications', {
        params: selfUser.hasUnitPerm
          ? {
              nursingHomeId,
              limit: 50,
              sort: 'desc',
              nursingHomeUnitId: selfUser.nursingHomeUnitId,
            }
          : !unitId
          ? { nursingHomeId, limit: 50, sort: 'desc' }
          : {
              nursingHomeId,
              limit: 50,
              sort: 'desc',
              nursingHomeUnitId: unitId,
            },
      });
      const residents = await api.get('/nursing-homes/:nursingHomeId/residents', {
        params: { nursingHomeId },
      });
      if (!notifications || !residents) return;
      const residentNotifications: ResidentNotification[] = notifications.map((notification) => {
        const filterResidents = isDeviceOnly ? residents.filter((resident) => resident.dfreeDeviceId) : residents;
        const resident = filterResidents.find((resident) => resident.id === notification.residentId);
        if (!resident) return { ...notification, residentName: '', residentLastName: '' };
        return {
          ...notification,
          residentName: resident.name,
          residentLastName: resident.lastName,
        };
      });
      setResidentNotifications(residentNotifications);
      hideLoading();
    },
    [selfUser, residentNotifications]
  );

  React.useEffect(() => {
    fetchNotifications(unitId);
  }, []);

  return (
    <div css={Container}>
      <ResidentListHeader
        title={t('notification', '通知')}
        conditions={conditions}
        unitId={unitId}
        onUpdate={handleUpdateNotifications}
        onChangeUnit={handleChangeNotifications}
      />
      {!residentNotifications.length ? (
        <div css={EmptyResidentContainer}>{t('noNotification', '通知はありません')}</div>
      ) : (
        <div css={NotificationListContainer}>
          {residentNotifications.map((notification) => (
            <NotificationListItem key={notification.id} notification={notification} />
          ))}
        </div>
      )}
    </div>
  );
};
