import React from 'react';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { IResidentCareRecord } from '../../../types/api/traversal/getNursingHomeCareRecord';
import { OutlineButton } from '../../../components/outlineButton';
import { color } from '../../../styles';
import dayjs from 'dayjs';
import { ResidentHeaderCareStatus } from '../../../components/residentItemHeader/careStatus';
import { ResidentItemHeader } from '../../../components/residentItemHeader';
import { useTranslation } from 'react-i18next';

interface Props {
  resident: IResidentCareRecord;
}

// 排泄表の上のテーブルデータ
interface IToiletTableData {
  isUrinatedToilet: boolean | null; // トイレで排尿したかどうか // true:有 false:無 null:未記入
  isDefecation: boolean | null; // トイレで排便したかどうか（量は無視）// true: 有 null: 未記入
}

interface IPadTableData {
  padUrineVolumeType: string | null; // パッドの尿量多 中 少 無で尿量推移
}

const Card = css`
  width: 100%;
  border-radius: 8px;
  background-color: ${color.white};
  margin-bottom: 16px;
`;
// TODO: ↑ Componentにmargin設定は本当は良くないけど取り急ぎ。margin設定はComponentをレイアウトしている親側でする

const HeaderContainer = (isCareTiming: boolean) => css`
  background-color: ${isCareTiming ? '#fae5a3' : 'transparent'};
  border-radius: 8px 8px 0 0;
`;

const HeaderRightContainer = css`
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: end;
  min-height: 48px;
  gap: 8px;
`;

const HeaderButtonContainer = css`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 12px;
  gap: 4px;
`;

const TableContainer = css`
  padding: 8px 16px 16px;
  border-top: 1px solid ${color.border_gray};
  overflow-x: auto;
`;
// TODO: 画面狭い時のスクロール処理を修正

const Table = css`
  border: 1px solid ${color.table_green};
  width: 100%;
  box-sizing: border-box;
`;

const ThTime = css`
  background: ${color.table_green};
  color: ${color.dark_gray};
`;

const ThTimeCell = css`
  border-left: 1px solid ${color.white};
  font-weight: normal;

  &:first-of-type {
    border-left: 1px solid ${color.table_green};
  }
`;

const ThLabel = css`
  text-align: left;
  border: 1px solid ${color.table_green};
  background-color: ${color.bg_light_green};
  padding: 0 8px;
  vertical-align: middle;
  font-size: 12px;
  font-weight: normal;
  color: ${color.dark_gray};
  min-width: 140px;
  & > * {
    margin-right: 4px;
  }
`;

const Td = css`
  position: relative;
  min-width: 36px;
  height: 36px;
  text-align: center;
  vertical-align: middle;
  border: 1px solid ${color.table_green};
`;

const TableImage = css`
  width: 28px;
  min-width: 28px;
  height: 28px;
`;

const TableLabel = css`
  margin-left: 4px;
`;

const ThAlert = (careTimings: number[], index: number) => css`
  background-color: ${careTimings.some((v) => v === index - 1) ? color.notification_yellow : 'transparent'};
`;

const TdAlert = (careTimings: number[], index: number) => css`
  background-color: ${careTimings.some((v) => v === index) ? color.notification_yellow_bg : 'transparent'};
`;

// TODO: 高負荷 処理速度が問題の場合はここを確認する
const CellMarker = (isDefecation: boolean | null) => {
  if (isDefecation) {
    return [
      css`
        &:after {
          position: absolute;
          content: '';
          top: 0;
          left: 0;
          z-index: 1;
          border-left: 14px solid ${color.brown};
          border-bottom: 14px solid transparent;
        }
      `,
    ];
  }
};

export const ExcretionTable = React.memo(({ resident }: Props) => {
  const hourPerDay = 24;
  const header = ['', 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
  const [toiletTableData, setToiletTableData] = React.useState<IToiletTableData[]>([]);
  const [padTableData, setPadTableData] = React.useState<IPadTableData[]>([]);
  const [careTimings, setCareTimings] = React.useState<number[]>([]);
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const isCareTimings = React.useMemo(() => {
    return careTimings.some((v) => {
      return dayjs().set('hour', v).hour() === dayjs().hour();
    });
  }, [careTimings]);

  const toiletCell = (data: IToiletTableData, i: number) => {
    return (
      <div css={[CellMarker(data.isDefecation)]} key={i}>
        {data.isUrinatedToilet === true ? (
          i18n.language === 'ja' ? (
            <img css={TableImage} src='/images/excretionTable/urine_large_ja.svg' alt='トイレ排尿 有' />
          ) : (
            <img css={TableImage} src='/images/excretionTable/urine_large_en.svg' alt='Has toilet urination' />
          )
        ) : data.isUrinatedToilet === false ? (
          i18n.language === 'ja' ? (
            <img css={TableImage} src='/images/excretionTable/urine_none_ja.svg' alt='トイレ排尿 無' />
          ) : (
            <img css={TableImage} src='/images/excretionTable/urine_none_en.svg' alt='No toilet urination' />
          )
        ) : (
          ''
        )}
      </div>
    );
  };

  const padCell = (data: IPadTableData, i: number) => {
    return (
      <React.Fragment key={i}>
        {data.padUrineVolumeType === 'large' ? (
          i18n.language === 'ja' ? (
            <img css={TableImage} src='/images/excretionTable/urine_large_ja.svg' alt='オムツ・パッド排尿 多' />
          ) : (
            <img css={TableImage} src='/images/excretionTable/urine_large_en.svg' alt='Has large diaper urination' />
          )
        ) : data.padUrineVolumeType === 'medium' ? (
          i18n.language === 'ja' ? (
            <img css={TableImage} src='/images/excretionTable/urine_medium_ja.svg' alt='オムツ・パッド排尿 中' />
          ) : (
            <img css={TableImage} src='/images/excretionTable/urine_medium_en.svg' alt='Has middle diaper urination' />
          )
        ) : data.padUrineVolumeType === 'small' ? (
          i18n.language === 'ja' ? (
            <img css={TableImage} src='/images/excretionTable/urine_small_ja.svg' alt='オムツ・パッド排尿 小' />
          ) : (
            <img css={TableImage} src='/images/excretionTable/urine_small_en.svg' alt='Has small diaper urination' />
          )
        ) : data.padUrineVolumeType === 'none' ? (
          i18n.language === 'ja' ? (
            <img css={TableImage} src='/images/excretionTable/urine_none_ja.svg' alt='オムツ・パッド排尿 無' />
          ) : (
            <img css={TableImage} src='/images/excretionTable/urine_none_en.svg' alt='No diaper urination' />
          )
        ) : (
          ''
        )}
      </React.Fragment>
    );
  };

  // 表データの作成、排尿回数のカウント
  React.useEffect(() => {
    if (!resident) return;
    let toiletTableData: IToiletTableData[] = [];
    let padTableData: IPadTableData[] = [];
    for (let i = 0; i < hourPerDay; i++) {
      toiletTableData.push({
        isUrinatedToilet: null,
        isDefecation: null,
      });
      padTableData.push({
        padUrineVolumeType: null,
      });
    }

    for (let i = 0; i < hourPerDay; i++) {
      resident.careRecords.forEach((v) => {
        if (dayjs(v.timestamp).local().hour() === i) {
          toiletTableData[i] = {
            isUrinatedToilet: v.data.urinatedToilet !== null ? v.data.urinatedToilet : null, // トイレで排尿したかどうか // true:有 false:無 null:未記入
            isDefecation: v.data.defecationVolumeType !== null ? !!v.data.defecationVolumeType : null, // トイレで排便したかどうか（量は無視）// true:チェック
          };
          padTableData[i] = {
            padUrineVolumeType: v.data.padUrineVolumeType !== null ? v.data.padUrineVolumeType : null, // パッドの尿量多 中 少 無で尿量推移
          };
        }
      });
    }

    const timings = resident.careTimings.map((v) =>
      dayjs()
        .utc()
        .set('hour', +v.slice(0, 2))
        .local()
        .hour()
    );
    setToiletTableData(toiletTableData);
    setPadTableData(padTableData);
    setCareTimings(timings);
  }, [resident]);

  return (
    <div css={Card}>
      <div css={HeaderContainer(isCareTimings)}>
        <ResidentItemHeader
          name={resident.residentName}
          unitName={resident.nursingHomeUnitName}
          roomName={resident.roomName}
          memo={resident.carePlanMemo}
          rightElement={
            <div css={[HeaderRightContainer]}>
              <ResidentHeaderCareStatus resident={resident} />
              <div css={HeaderButtonContainer}>
                <OutlineButton
                  bgColor={color.white}
                  onClick={() => {
                    history.push(`residents/${resident.residentId}/care-record`);
                  }}
                >
                  {t('detailOrEdit', '詳細 / 編集')}
                </OutlineButton>
                <OutlineButton
                  bgColor={color.white}
                  onClick={() => {
                    history.push(`residents/${resident.residentId}/edit`);
                  }}
                  icon={<img src='/images/icons/resident-setting.svg' alt={t('residentSetting', '入居者設定')} />}
                >
                  {t('setting', '設定')}
                </OutlineButton>
              </div>
            </div>
          }
        />
      </div>
      <div css={TableContainer}>
        <table css={Table}>
          <thead css={ThTime}>
            <tr>
              {header.map((v, i) => {
                return (
                  <th css={[ThTimeCell, ThAlert(careTimings, i)]} key={i}>
                    {v}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            <tr>
              <th css={ThLabel}>
                <img css={TableImage} src='/images/careRecord/toilet.svg' alt={t('toilet', 'トイレ')} />
                <span css={TableLabel}>{t('toilet', 'トイレ')}</span>
              </th>
              {toiletTableData.map((v, i) => {
                return (
                  <td css={[Td, TdAlert(careTimings, i)]} key={i}>
                    {toiletCell(v, i)}
                  </td>
                );
              })}
            </tr>
            <tr>
              <th css={ThLabel}>
                <img css={TableImage} src='/images/careRecord/diaper.svg' alt='' />
                <span css={TableLabel}>{t('diaperOrPad', 'おむつ/パッド')}</span>
              </th>
              {padTableData.map((v, i) => {
                return (
                  <td css={[Td, TdAlert(careTimings, i)]} key={i}>
                    {padCell(v, i)}
                  </td>
                );
              })}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
});
