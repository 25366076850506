import React from 'react';
import { css } from '@emotion/react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import Button from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MenuIcon from '@mui/icons-material/Menu';
import { Menu, MenuItem } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { selfDataSelector, selfDataSlice } from '../redux/config/selfData';
import { color } from '../styles';
import { IGetNursingHomeRes } from '../types/api/nursingHome/getNursingHome';
import { currentNursingHomeSelector, currentNursingHomeSlice } from '../redux/config/currentNursingHome';
import { useTranslation } from 'react-i18next';
import api from '../api';
import { filterConditionSlice } from '../redux/config/filterCondition';
import { styled } from '@mui/material';
import { NavLink } from 'react-router-dom';

interface Props {
  nursingHomes: IGetNursingHomeRes[] | null;
  isOpenLeftnav: boolean;
  onOpen: () => void;
  fetchBadge: (nursingHomeId: string, unitId: number | null) => void;
}

const Container = css`
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  width: 100%;
  padding: 0 16px;
  background-color: ${color.white};
`;

const LeftContainer = css`
  display: flex;
  align-items: center;
`;

const RightContainer = css`
  span {
    color: ${color.black};
  }
`;

const NursingHomeName = css`
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
`;

const UnitName = css`
  font-size: 12px;
  line-height: 12px;
`;

const Img = css`
  width: 32px;
  height: 32px;
  margin: 0 4px;
`;

const HamburgerIcon = css`
  margin: 12px;
`;

const LinkButton = styled(NavLink)`
  text-decoration: none;
  padding-right: 16px;
  color: ${color.dark_gray};
  font-size: 14px;
  &:hover {
    color: ${color.green};
  }
`;

export const GlobalHeader = React.memo(({ nursingHomes, isOpenLeftnav, onOpen, fetchBadge }: Props) => {
  const { nursingHomeId } = useParams<{ nursingHomeId: string }>();
  const selfData = useSelector(selfDataSelector);
  const currentNursingHome = useSelector(currentNursingHomeSelector);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [filteredNursingHomes, setFilteredNursingHomes] = React.useState<IGetNursingHomeRes[] | null>(null);
  const history = useHistory();
  const { t } = useTranslation();
  const { url } = useRouteMatch();

  const handleFacilityOpenButton = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFacilityClick = async (nursingHome: IGetNursingHomeRes) => {
    const units = await api.get('/nursing-homes/:nursingHomeId/nursing-home-units', {
      params: { nursingHomeId: nursingHome.id },
    });
    dispatch(selfDataSlice.actions.setSelfUnits(units));
    dispatch(currentNursingHomeSlice.actions.setCurrentNursingHome(nursingHome));
    dispatch(filterConditionSlice.actions.setFilterCondition({ unitId: null, conditions: 'all' }));
    localStorage.setItem('unit', 'null');
    localStorage.setItem('condition', 'all');
    // 施設変更時は変更するIDをもとにバッジを表示する
    fetchBadge(String(nursingHome.id), null);
    handleClose();
    history.push(`/nursinghome/${nursingHome.id}/residents`);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    if (nursingHomes !== null) {
      // 現在のNursingHomeIdと一致するものはListから除外
      const filteredNursingHomes = nursingHomes?.filter((nursingHome) => {
        return nursingHome.id !== +nursingHomeId;
      });
      setFilteredNursingHomes(filteredNursingHomes);
    }
  }, [nursingHomes, nursingHomeId]);

  return (
    <>
      <div css={Container}>
        <div css={LeftContainer}>
          {!isOpenLeftnav && <MenuIcon css={HamburgerIcon} onClick={onOpen} />}
          <div>
            <div css={NursingHomeName}>{currentNursingHome.name}</div>
            {!!selfData.selfUnit.name && <div css={UnitName}>{selfData.selfUnit.name}</div>}
          </div>
        </div>
        <div css={RightContainer}>
          <LinkButton to={`${url}/announcements`}>{t('information', 'お知らせ')}</LinkButton>
          {!selfData.selfUser.hasUnitPerm && (
            <img css={Img} src='/images/header/icon_facility.svg' alt='施設アイコン' />
          )}
          {selfData.selfUser.professionalRole !== 'member' && nursingHomes && (
            <>
              <Button onClick={handleFacilityOpenButton}>
                <span>
                  {t('facilitiesChange', '施設変更')}
                  <ArrowDropDownIcon />
                </span>
              </Button>
              <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose}>
                {filteredNursingHomes &&
                  filteredNursingHomes.map((nursingHome, key) => (
                    <MenuItem key={key} onClick={() => handleFacilityClick(nursingHome)}>
                      {nursingHome.name}
                    </MenuItem>
                  ))}
              </Menu>
            </>
          )}
        </div>
      </div>
    </>
  );
});
