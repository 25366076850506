import { ICreateResidentParams } from '../types/api/resident/createResident';
import { IUpdateResidentParams } from '../types/api/resident/updateResident';

export const CARE_LEVEL = [
  { key: 1, value: '1' },
  { key: 2, value: '2' },
  { key: 3, value: '3' },
  { key: 4, value: '4' },
  { key: 5, value: '5' },
];

export const PURPOSE_JA = [
  { key: 'toilet', value: '「そろそろ通知」をもとにトイレ誘導' },
  { key: 'diaper', value: '「でたかも通知」をもとにおむつ交換' },
  { key: 'monitoring', value: '排泄パターン把握(モニタリング)' },
];

export const PURPOSE_EN = [
  { key: 'toilet', value: 'Toilet guidance based on "Almost" notification' },
  { key: 'diaper', value: 'Change diapers based on "Check" notification' },
  { key: 'monitoring', value: 'Understanding excretion patterns (monitoring)' },
];

// 0はnullとして送信する
export const SNOOZETERM_JA = [
  { key: '0', value: 'なし' },
  { key: '5', value: '5分' },
  { key: '10', value: '10分' },
  { key: '15', value: '15分' },
  { key: '30', value: '30分' },
];

// 0はnullとして送信する
export const SNOOZETERM_EN = [
  { key: '0', value: 'No' },
  { key: '5', value: '5 mins' },
  { key: '10', value: '10 mins' },
  { key: '15', value: '15 mins' },
  { key: '30', value: '30 mins' },
];

export const SNOOZEPERIOD_JA = [
  { key: '5', value: '5分' },
  { key: '10', value: '10分' },
  { key: '15', value: '15分' },
  { key: '30', value: '30分' },
  { key: '60', value: '1時間' },
  { key: '120', value: '2時間' },
];

export const SNOOZEPERIOD_EN = [
  { key: '5', value: '5 mins' },
  { key: '10', value: '10 mins' },
  { key: '15', value: '15 mins' },
  { key: '30', value: '30 mins' },
  { key: '60', value: '1 hour' },
  { key: '120', value: '2 hours' },
];

export const INDEPENDENCE_LEVELS = [
  { key: 'unnecessary', value: '自立' },
  { key: 'part', value: '一部介助' },
  { key: 'whole', value: '全部介助' },
];

export const HOURS = [
  { key: '00:00:00Z', value: '00:00' },
  { key: '00:30:00Z', value: '00:30' },
  { key: '01:00:00Z', value: '01:00' },
  { key: '01:30:00Z', value: '01:30' },
  { key: '02:00:00Z', value: '02:00' },
  { key: '02:30:00Z', value: '02:30' },
  { key: '03:00:00Z', value: '03:00' },
  { key: '03:30:00Z', value: '03:30' },
  { key: '04:00:00Z', value: '04:00' },
  { key: '04:30:00Z', value: '04:30' },
  { key: '05:00:00Z', value: '05:00' },
  { key: '05:30:00Z', value: '05:30' },
  { key: '06:00:00Z', value: '06:00' },
  { key: '06:30:00Z', value: '06:30' },
  { key: '07:00:00Z', value: '07:00' },
  { key: '07:30:00Z', value: '07:30' },
  { key: '08:00:00Z', value: '08:00' },
  { key: '08:30:00Z', value: '08:30' },
  { key: '09:00:00Z', value: '09:00' },
  { key: '09:30:00Z', value: '09:30' },
  { key: '10:00:00Z', value: '10:00' },
  { key: '10:30:00Z', value: '10:30' },
  { key: '11:00:00Z', value: '11:00' },
  { key: '12:00:00Z', value: '12:00' },
  { key: '13:00:00Z', value: '13:00' },
  { key: '14:00:00Z', value: '14:00' },
  { key: '15:00:00Z', value: '15:00' },
  { key: '16:00:00Z', value: '16:00' },
  { key: '17:00:00Z', value: '17:00' },
  { key: '18:00:00Z', value: '18:00' },
  { key: '19:00:00Z', value: '19:00' },
  { key: '20:00:00Z', value: '20:00' },
  { key: '21:00:00Z', value: '21:00' },
  { key: '22:00:00Z', value: '22:00' },
  { key: '23:00:00Z', value: '23:00' },
];

export const HOURS_WITH_NOTHING = [
  { key: '-', value: 'なし' },
  { key: '00:00:00Z', value: '00:00' },
  { key: '00:30:00Z', value: '00:30' },
  { key: '01:00:00Z', value: '01:00' },
  { key: '01:30:00Z', value: '01:30' },
  { key: '02:00:00Z', value: '02:00' },
  { key: '02:30:00Z', value: '02:30' },
  { key: '03:00:00Z', value: '03:00' },
  { key: '03:30:00Z', value: '03:30' },
  { key: '04:00:00Z', value: '04:00' },
  { key: '04:30:00Z', value: '04:30' },
  { key: '05:00:00Z', value: '05:00' },
  { key: '05:30:00Z', value: '05:30' },
  { key: '06:00:00Z', value: '06:00' },
  { key: '06:30:00Z', value: '06:30' },
  { key: '07:00:00Z', value: '07:00' },
  { key: '07:30:00Z', value: '07:30' },
  { key: '08:00:00Z', value: '08:00' },
  { key: '08:30:00Z', value: '08:30' },
  { key: '09:00:00Z', value: '09:00' },
  { key: '09:30:00Z', value: '09:30' },
  { key: '10:00:00Z', value: '10:00' },
  { key: '10:30:00Z', value: '10:30' },
  { key: '11:00:00Z', value: '11:00' },
  { key: '12:00:00Z', value: '12:00' },
  { key: '13:00:00Z', value: '13:00' },
  { key: '14:00:00Z', value: '14:00' },
  { key: '15:00:00Z', value: '15:00' },
  { key: '16:00:00Z', value: '16:00' },
  { key: '17:00:00Z', value: '17:00' },
  { key: '18:00:00Z', value: '18:00' },
  { key: '19:00:00Z', value: '19:00' },
  { key: '20:00:00Z', value: '20:00' },
  { key: '21:00:00Z', value: '21:00' },
  { key: '22:00:00Z', value: '22:00' },
  { key: '23:00:00Z', value: '23:00' },
];

export const CARE_SCHEDULES = [
  { key: '00:00:00Z', value: '0' },
  { key: '01:00:00Z', value: '1' },
  { key: '02:00:00Z', value: '2' },
  { key: '03:00:00Z', value: '3' },
  { key: '04:00:00Z', value: '4' },
  { key: '05:00:00Z', value: '5' },
  { key: '06:00:00Z', value: '6' },
  { key: '07:00:00Z', value: '7' },
  { key: '08:00:00Z', value: '8' },
  { key: '09:00:00Z', value: '9' },
  { key: '10:00:00Z', value: '10' },
  { key: '11:00:00Z', value: '11' },
  { key: '12:00:00Z', value: '12' },
  { key: '13:00:00Z', value: '13' },
  { key: '14:00:00Z', value: '14' },
  { key: '15:00:00Z', value: '15' },
  { key: '16:00:00Z', value: '16' },
  { key: '17:00:00Z', value: '17' },
  { key: '18:00:00Z', value: '18' },
  { key: '19:00:00Z', value: '19' },
  { key: '20:00:00Z', value: '20' },
  { key: '21:00:00Z', value: '21' },
  { key: '22:00:00Z', value: '22' },
  { key: '23:00:00Z', value: '23' },
];

export const BLADDER_THRESHOLD_PERCENTAGE = [
  { key: 10, value: '1' },
  { key: 20, value: '2' },
  { key: 30, value: '3' },
  { key: 40, value: '4' },
  { key: 50, value: '5' },
  { key: 60, value: '6' },
  { key: 70, value: '7' },
  { key: 80, value: '8' },
  { key: 90, value: '9' },
  { key: 100, value: '10' },
];

export const DEFAULT_RESIDENT_FORM_VALUE: ICreateResidentParams = {
  nursingHomeId: 0,
  nursingHomeUnitId: null,
  nursingHomeUnitName: '',
  name: '',
  lastName: '',
  roomName: '',
  carePlanMemo: '',
  bed: '',
  bladderThresholdPercentage: 60,
  bigBladderNotification: false,
  urinationNotification: false,
  gotUpNotification: false,
  zeroResetNotification: true,
  beginGotUpCheckTerm: '19:00:00Z',
  endGotUpCheckTerm: '07:00:00Z',
  contingencyMode: false,
  gasPercentage: null,
  gasCareStatus: null,
  gasLevelStatus: null,
  gasLevelThreshold: null,
  gasPoopedNotification: false,
  purpose: 'monitoring',
  bigBladderSnoozeTerm: 5,
  bigBladderSnoozePeriod: 60,
  urinationSnoozeTerm: 5,
  urinationSnoozePeriod: 60,
  gotUpSnoozeTerm: 5,
  gotUpSnoozePeriod: 60,
  memo: null,
  careSettings: {
    careLevel: null,
    independenceLevel: null,
    beginDiaperMiddleHour: null,
    endDiaperMiddleHour: null,
    beginDiaperNightHour: '12:00:00Z',
    endDiaperNightHour: '23:00:00Z',
    nursingHomeDiaperIdNoon: null,
    nursingHomePadIdNoonFirst: null,
    nursingHomePadIdNoonSecond: null,
    nursingHomeDiaperIdMiddle: null,
    nursingHomePadIdMiddleFirst: null,
    nursingHomePadIdMiddleSecond: null,
    nursingHomeDiaperIdNight: null,
    nursingHomePadIdNightFirst: null,
    nursingHomePadIdNightSecond: null,
    laxativeId: null,
  },
  goodTreeResidentId: null,
};

export const DEFAULT_RESIDENT_UPDATE_FORM_VALUE: IUpdateResidentParams = {
  id: 0,
  nursingHomeId: 0,
  nursingHomeUnitId: null,
  nursingHomeUnitName: '',
  name: '',
  lastName: '',
  roomName: '',
  dfreeDeviceId: null,
  carePlanMemo: '',
  bladderThresholdPercentage: 60,
  bigBladderNotification: true,
  urinationNotification: false,
  gotUpNotification: false,
  zeroResetNotification: true,
  beginGotUpCheckTerm: '19:00:00Z',
  endGotUpCheckTerm: '07:00:00Z',
  contingencyMode: false,
  gasPercentage: null,
  gasCareStatus: null,
  gasLevelStatus: null,
  gasLevelThreshold: null,
  gasPoopedNotification: false,
  purpose: 'monitoring',
  bigBladderSnoozeTerm: null,
  bigBladderSnoozePeriod: null,
  urinationSnoozeTerm: null,
  urinationSnoozePeriod: null,
  gotUpSnoozeTerm: null,
  gotUpSnoozePeriod: null,
  memo: null,
  careSettings: {
    careLevel: null,
    independenceLevel: null,
    beginDiaperMiddleHour: '06:00:00Z',
    endDiaperMiddleHour: '12:00:00Z',
    beginDiaperNightHour: '12:00:00Z',
    endDiaperNightHour: '23:00:00Z',
    nursingHomeDiaperIdNoon: null,
    nursingHomePadIdNoonFirst: null,
    nursingHomePadIdNoonSecond: null,
    nursingHomeDiaperIdMiddle: null,
    nursingHomePadIdMiddleFirst: null,
    nursingHomePadIdMiddleSecond: null,
    nursingHomeDiaperIdNight: null,
    nursingHomePadIdNightFirst: null,
    nursingHomePadIdNightSecond: null,
    laxativeId: null,
  },
  goodTreeResidentId: null,
};

export interface ILifeResidentForm {
  firstFurigana: string;
  lastFurigana: string;
  serviceCode: string;
  insurerNo: string;
  insuredNo: string;
  birthday: string;
  certifiedDate: string;
  carePeriodStart: string;
  carePeriodEnd: string;
  lifeCareLevel: string;
  impairedElderlyIndependenceDegree: string;
  dementiaElderlyIndependenceDegree: string;
  startDate: string;
  endDate: string;
  deathDate: string;
  remarks: string;
  id: number;
}

export const DEFAULT_LIFE_RESIDENT_FORM_VALUE: ILifeResidentForm = {
  firstFurigana: '',
  lastFurigana: '',
  serviceCode: '',
  insurerNo: '',
  insuredNo: '',
  birthday: '',
  certifiedDate: '',
  carePeriodStart: '',
  carePeriodEnd: '',
  lifeCareLevel: '',
  impairedElderlyIndependenceDegree: '',
  dementiaElderlyIndependenceDegree: '',
  startDate: '',
  endDate: '',
  deathDate: '',
  remarks: '',
  id: 0,
};