import React from "react";
import { SerializedStyles } from "@emotion/react";
import { Select as MUISelect, SelectChangeEvent } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";

interface Props {
  id?: string;
  listData: MenuItemProps[]; // 一覧に表示するリストデータ
  value?: MenuItemProps | string | number | null; // 初期値として使用
  label?: string; // フォームの説明文
  isError?: boolean; // フォームがErrorの場合
  errorText?: string; // Error時に表示するヘルプテキスト
  disabled?: boolean; // フォームを無効化するかどうか
  className?: SerializedStyles; // コンポーネントに適用するEmotionCSS
  style?: React.CSSProperties; // Formに指定するCSSProps
  fullWidth?: boolean;
  onChange: (value: MenuItemProps) => void;
}

export interface MenuItemProps {
  key: number | string;
  value: string;
  option?: any;
}

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 400,
    },
  },
};

export const Select = React.memo(
  ({
    id,
    listData,
    value,
    label = '',
    isError = false,
    errorText = '',
    disabled = false,
    className,
    style,
    fullWidth = false,
    onChange,
  }: Props) => {
    const [displayText, setDisplayText] = React.useState('');
    const [helperText, setHelperText] = React.useState<string>('');

    React.useEffect(() => {
      if (isError) {
        setHelperText(errorText);
      } else {
        setHelperText('');
      }
    }, [isError]);

    const handleChange = (data: MenuItemProps) => {
      setDisplayText(data.key as string);
      onChange(data);
    };

    React.useEffect(() => {
      if (!value) {
        setDisplayText('');
        return;
      } else {
        typeof value === 'object' ? setDisplayText(value.key as string) : setDisplayText(value as string);
      }
    }, [value]);

    return (
      <>
        {listData && (
          <FormControl style={style} fullWidth={fullWidth}>
            <InputLabel id={id + '-label'}>{label}</InputLabel>
            <MUISelect
              style={{ background: '#fff' }}
              id={id}
              labelId={id + '-label'}
              label={label}
              value={displayText}
              css={className}
              disabled={disabled}
              MenuProps={MenuProps}
              error={isError}
            >
              {listData.map((data: any, i: number) => {
                return (
                  <MenuItem key={i} value={data.key} onClick={() => handleChange(data)}>
                    {data.value}
                  </MenuItem>
                );
              })}
            </MUISelect>
            <FormHelperText sx={{ color: '#d32f2f' }}>{helperText}</FormHelperText>
          </FormControl>
        )}
      </>
    );
  }
);
