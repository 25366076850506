import React from 'react';
import dayjs from 'dayjs';
import Highcharts from 'highcharts';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { FormControlLabel, Checkbox, Drawer, styled, TextField, Button as MUIButton, IconButton } from '@mui/material';
import { HelpOutline } from '@mui/icons-material';
import { css } from '@emotion/react';
import { currentNursingHomeSelector } from '../../../../redux/config/currentNursingHome';
import { v4 as uuid } from 'uuid';
import api from '../../../../api';
import { color } from '../../../../styles';
import { useLoading } from '../../../../contexts/LoadingContext';
import { useSnackbar } from '../../../../contexts/SnackbarContext';
import { Button } from '../../../../components/mui/button';
import { OutlineButton } from '../../../../components/outlineButton';
import { RecordDialog } from '../../../../components/careRecord/recordDialog';
import { IGetUltraSonicDataSetsRes } from '../../../../types/api/ultra-sonic-data/getUltraSonicDataSets';
import { IUpdateResidentParams } from '../../../../types/api/resident/updateResident';
import { IGetNotificationRes } from '../../../../types/api/notification/getNotification';
import { IGetCareRecordRes } from '../../../../types/api/care-record/getCareRecord';
import { IGetResidentRes } from '../../../../types/api/resident/getResident';
import { IGetSensorReaction, IGetSensorReactionRes } from '../../../../types/api/ultra-sonic-data/getSensorReaction';
import { IGetResamples, IResample } from '../../../../types/api/ultra-sonic-data/getResample';
import { DetailGraphItem } from './detailGraphItem';
import { ChangeLineModal } from './changeLine';
import { Dataset } from './dataset';

interface Props {
  residentId: string;
}

interface ScatterItem {
  x: number;
  y: number;
  color: string;
  name: string;
}

const Container = css``;

const GraphContainer = css`
  padding: 8px 24px 24px;
  margin: 12px auto;
  z-index: 0;
`;

const GraphHeader = css`
  position: sticky;
  z-index: 1;
  top: 0;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.17);
  padding: 24px 24px 8px;
  background: ${color.white};
`;

const ButtonContainer = css`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const ButtonRightElement = css`
  display: flex;
  align-items: center;
`;

const LineChangeButton = styled(Button)`
  border-radius: 60px;
  padding: 8px 32px;
  font-weight: bold;
  font-size: 14px;
  background: ${color.notification_yellow};

  &:hover {
    background: ${color.notification_yellow};
  }
`;

const DatePickerContainer = css`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 16px;
`;

const CareRecordLabelRow = css`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const CareRecordLabel = css`
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 100%;
  margin-right: 32px;

  color: ${color.dark_gray};
  & > span {
    margin-left: 8px;
  }
`;

const ColorRect = css`
  border: 1px solid ${color.border_gray};
  width: 12px;
  height: 12px;
`;

const ColorLine = css`
  background: ${color.notification_yellow};
  width: 30px;
  height: 3px;
`;

const ColorCircle = css`
  width: 12px;
  height: 12px;
  border-radius: 6px;
`;

const CaptionTitle = css`
  line-height: 1.8em;
  font-size: 24px;
  font-weight: bold;
`;

const CaptionContainer = css`
  display: grid;
  margin-bottom: 16px;
`;

const CaptionText = css`
  line-height: 1.8em;
  font-size: 18px;
  font-weight: bold;
  color: ${color.green};
`;

const AnnotationText = css`
  line-height: 1.6em;
  font-size: 14px;
`;

export const ResidentDetailGraph: React.FC<Props> = ({ residentId }) => {
  const { showSnackbar } = useSnackbar();
  const { showLoading, hideLoading } = useLoading();
  const { t } = useTranslation();
  const currentNursingHome = useSelector(currentNursingHomeSelector);
  const [range, setRange] = React.useState<{ start: dayjs.Dayjs; end: dayjs.Dayjs; isCreate: boolean }>({
    start: dayjs().local().set('hour', 0).set('minute', 0).set('second', 0).utc(),
    end: dayjs().local().add(1, 'day').set('hour', 0).set('minute', 0).set('second', 0).utc(),
    isCreate: false,
  });
  const [charts, setCharts] = React.useState<
    { id: string; option: Highcharts.Options; range: { start: dayjs.Dayjs; end: dayjs.Dayjs } }[]
  >([]);
  const [isDrawerOpen, setDrawerOpen] = React.useState(false);
  const [isLineChangeModalOpen, setLineChangeModalOpen] = React.useState(false);
  const [isUrineVolumeCaptionOpen, setUrineVolumeCaptionOpen] = React.useState(false);
  const [isAbsoluteViewMode, setAbsoluteViewMode] = React.useState(false);
  const [isResampleView, setResampleView] = React.useState(false);
  const [datasets, setDataset] = React.useState<IGetUltraSonicDataSetsRes>();
  const [resident, setResident] = React.useState<IGetResidentRes>();

  const fetchDataset = React.useCallback(async () => {
    if (!range) return;
    showLoading();
    await Promise.all([
      await api.get('/residents/:residentId/ultra-sonic-data-sets', {
        params: { residentId, sort: 'desc', limit: 5000 },
      }),
      await api.get('/residents/:residentId/favorites/data-set', {
        params: { residentId },
      }),
    ]).then(([datasets, favorites]) => {
      // データセットドロワー部分で表示したいものだけにする
      const filterDatasets = datasets.filter(
        (v) =>
          v.count > 1000 ||
          dayjs(v.end).isBetween(dayjs(new Date()).subtract(5, 'minutes'), dayjs(new Date()).add(5, 'minutes'))
      );
      const datasetsWithFavorite = (filterDatasets || []).map((v) => {
        if (!favorites) return { ...v, isFavorite: false };
        const favoriteDate = favorites.find((favorite) => favorite.from === v.begin);
        if (!favoriteDate) return { ...v, isFavorite: false };
        return { ...v, isFavorite: true, favoriteId: favoriteDate.id };
      });
      setDataset(datasetsWithFavorite);
    });
    hideLoading();
  }, [range]);

  const handleUpdateFavorite = React.useCallback(
    async (start: string, end: string, favoriteId?: number) => {
      if (!datasets) return;
      const favoriteIndex = datasets.findIndex((v) => v.begin === start);
      if (favoriteIndex === -1) return;
      if (!favoriteId) {
        const res = await api.post('/residents/:residentId/favorites/data-set', { residentId, from: start, to: end });
        setDataset((prev) => {
          if (!prev) return [];
          prev[favoriteIndex].favoriteId = res.id;
          prev[favoriteIndex].isFavorite = true;
          return [...prev];
        });
      } else {
        await api.delete('/residents/:residentId/favorites/data-set/:favoriteDatasetId', {
          params: { residentId, favoriteDatasetId: favoriteId },
        });
        setDataset((prev) => {
          if (!prev) return [];
          prev[favoriteIndex].favoriteId = undefined;
          prev[favoriteIndex].isFavorite = false;
          return [...prev];
        });
      }
    },
    [datasets]
  );

  const handleUpdateLine = React.useCallback(async (data: IUpdateResidentParams) => {
    handleCloseLineChangeModal();
    try {
      await api.put('/residents/:residentId', { residentId, ...data });
      fetchCharts();
      showSnackbar(t('almostLineChanged', 'そろそろラインを変更しました'));
    } catch (e) {
      showSnackbar('エラーが発生しました', 'error');
    }
  }, []);

  const handleCreateChart = React.useCallback((start: string, end: string) => {
    setRange({ start: dayjs(start).local().utc(), end: dayjs(end).local().utc(), isCreate: true });
  }, []);

  const handleUpdateChart = React.useCallback((start: string, end: string) => {
    setRange({ start: dayjs(start).local().utc(), end: dayjs(end).local().utc(), isCreate: false });
    handleCloseDrawer();
  }, []);

  const handleOpenLineChangeModal = React.useCallback(() => {
    setLineChangeModalOpen(true);
  }, []);

  const handleCloseLineChangeModal = React.useCallback(() => {
    setLineChangeModalOpen(false);
  }, []);

  const handleOpenDrawer = React.useCallback(() => {
    if (datasets && datasets.length > 0) {
      setDrawerOpen(true);
    } else {
      showSnackbar(t('noDataset', 'データセットがありません'));
    }
  }, [datasets]);

  const handleCloseDrawer = React.useCallback(() => {
    setDrawerOpen(false);
  }, []);

  const handleDeleteGraph = React.useCallback(
    (id: string) => {
      setCharts((prev) => {
        return prev.filter((v) => v.id !== id);
      });
    },
    [charts]
  );

  const yAxisLabelFormatter = React.useCallback(
    (value: any, isAbsoluteMode: boolean) => {
      return isAbsoluteMode ? (+value * 40).toString() + 'ml' : value.toString();
    },
    [charts, isAbsoluteViewMode]
  );

  const tooltipFormatter = React.useCallback(
    (x: any, y: any, isAbsoluteMode: boolean) => {
      const dateFormat = t('dayjsGraphDateTimeFormat', 'MM月DD日 HH時mm分');
      const localTime = dayjs(x).format(dateFormat);
      const yValue = y !== undefined ? (isAbsoluteMode ? (y * 40).toString() + 'ml' : y) : '';
      return `${yValue}<br />${localTime}`;
    },
    [charts, isAbsoluteViewMode]
  );

  const getYGrids = React.useCallback((bigBladderNotification = false, threshold = 0) => {
    let grids: Highcharts.YAxisPlotLinesOptions[] = [];

    grids = [
      ...grids,
      {
        color: bigBladderNotification ? '#FFBB00' : '#C0C0C0',
        dashStyle: 'Solid',
        value: threshold / 10 - 0.03,
        width: 2,
        zIndex: -3,
      },
    ];
    return grids;
  }, []);

  const toggleAbsoluteSensorReaction = React.useCallback(
    (isChecked: boolean) => {
      setAbsoluteViewMode(isChecked);
      const toggleCharts = (charts || []).map((chart) => {
        if (!chart.option.series) return { ...chart };

        // y軸目盛を絶対値↔︎相対値に切り替える
        const yAxis = chart.option.yAxis;
        if (Array.isArray(yAxis) && yAxis[0] && yAxis[0].labels) {
          yAxis[0].labels.formatter = function () {
            return yAxisLabelFormatter(this.value, isChecked);
          };
        } else if (!Array.isArray(yAxis) && yAxis?.labels) {
          yAxis.labels.formatter = function () {
            return yAxisLabelFormatter(this.value, isChecked);
          };
        }

        // tooltip.pointformatを絶対値↔︎相対値に切り替える
        if (chart.option.series[0].tooltip) {
          chart.option.series[0].tooltip.pointFormatter = function () {
            return tooltipFormatter(this.x, this.y, isChecked);
          };
        }
        if (chart.option.series[1].tooltip) {
          chart.option.series[1].tooltip.pointFormatter = function () {
            return tooltipFormatter(this.x, this.y, isChecked);
          };
        }

        return { ...chart };
      });
      setCharts(toggleCharts);
    },
    [isAbsoluteViewMode, charts]
  );

  const toggleResample = React.useCallback(
    (isChecked: boolean) => {
      const toggleCharts = (charts || []).map((chart) => {
        if (!chart.option.series) return { ...chart };
        chart.option.series[1].visible = isChecked;
        return { ...chart };
      });
      setCharts(toggleCharts);
    },
    [isResampleView, charts]
  );

  const createSensorReactionData = React.useCallback(
    (data: IGetSensorReactionRes) => {
      const destSensorReactions: [number, number | null][] = [];
      let prevTimestamp: number;

      data.forEach((v: IGetSensorReaction) => {
        // 前後から25分以上離れていたら切断
        const timestamp = Date.parse(v.timestamp);
        if (timestamp - prevTimestamp > 60 * 1000 * 25) {
          destSensorReactions.push([timestamp - 60 * 1000, null]);
        }
        prevTimestamp = timestamp;
        destSensorReactions.push([timestamp, v.u1xActiveRatio * 10]);
      });

      return destSensorReactions;
    },
    [residentId, range, resident, isResampleView, isAbsoluteViewMode]
  );

  const createResampleData = React.useCallback(
    (data: IGetResamples) => {
      const destResamples: [number, number | null][] = [];
      let prevTimestamp: number;

      data.map((v: IResample) => {
        // 前後から25分以上離れていたら切断
        const timestamp = Date.parse(v.timestamp);
        if (timestamp - prevTimestamp > 60 * 1000 * 25) {
          destResamples.push([timestamp - 60 * 1000, null]);
        }
        prevTimestamp = timestamp;
        destResamples.push([timestamp, v.semiSensorSummary * 10]);
      });

      return destResamples;
    },
    [residentId, range, resident, isResampleView, isAbsoluteViewMode]
  );

  const createNotificationScatter = React.useCallback(
    (data: IGetNotificationRes[]) => {
      const scatterData = data
        .filter((notification) => {
          const { notificationType } = notification;
          return notificationType === 'urinated' || notificationType === 'bigBladder' || notificationType === 'gotUp';
        })
        .map((notification) => {
          const { notificationType, timestamp } = notification;
          let label = '';
          let color = '';
          if (notificationType === 'urinated') {
            label = t('checkGraphLabel', 'でたかも');
            color = 'rgba(255, 143, 165, 0.8)';
          } else if (notificationType === 'bigBladder') {
            label = t('almostGraphLabel', 'そろそろ');
            color = 'rgba(255, 187, 0, 0.8)';
          } else {
            label = t('gotUpGraphLabel', '起き上がり');
            color = 'rgba(255, 50, 35, 0.8)';
          }

          return {
            x: Date.parse(timestamp),
            y: 0,
            name: label,
            color: color,
          };
        });
      return scatterData.length ? scatterData : [];
    },
    [residentId, range, resident, isResampleView, isAbsoluteViewMode]
  );

  const createCareRecordScatter = React.useCallback(
    (data: IGetCareRecordRes[]) => {
      const careRecordPlots = (data || []).map((v: IGetCareRecordRes) => {
        return { timestamp: v.timestamp, toilet: v.urinatedToilet, pad: v.padUrineVolumeType !== 'none' };
      });

      const scatterData = (careRecordPlots || []).map((data) => {
        const { toilet, pad, timestamp } = data;
        const label = `${t('toilet', 'トイレ')}: ${toilet ? t('yes', '有') : t('no', '無')} ${t(
          'diaper',
          'おむつ/パッド'
        )}: ${pad ? t('yes', '有') : t('no', '無')}`;
        let color = '';
        if (toilet && !pad) color = '#72fbfd';
        if (toilet && pad) color = '#75f94c';
        if (!toilet && pad) color = '#CCCCCC';
        if (!toilet && !pad) color = '#6D6D6D';
        return {
          x: Date.parse(timestamp),
          y: 10,
          name: label,
          color,
        };
      });

      return scatterData.length ? scatterData : [];
    },
    [residentId, range, resident, isResampleView, isAbsoluteViewMode]
  );

  const fetchCharts = React.useCallback(() => {
    Promise.all([
      api.get('/residents/:residentId', { params: { residentId } }),
      api.get('/residents/:residentId/sensor-reactions', {
        params: { residentId, from: range.start.format(), to: range.end.format(), sort: 'asc' },
      }),
      api.get('/residents/:residentId/notifications', {
        params: { residentId, from: range.start.format(), to: range.end.format(), limit: 10000, sort: 'asc' },
      }),
      api.get('/traversal/residents/:residentId/sensor-reaction-resamples', {
        params: { residentId, from: range.start.format(), to: range.end.format(), sort: 'asc' },
      }),
      api.get('/residents/:residentId/care-records', {
        params: {
          residentId,
          from: range.start.format(),
          to: range.end.format(),
          limit: 10000,
        },
      }),
    ]).then(([resident, sensorReactions, notifications, resamples, records]) => {
      const sensorReactionData = createSensorReactionData(sensorReactions);
      const resampleData = createResampleData(resamples);
      const notificationScatter = createNotificationScatter(notifications);
      const careRecordScatter = createCareRecordScatter(records);

      setResident(resident);
      setOption(resident, sensorReactionData, resampleData, notificationScatter, careRecordScatter);
    });
  }, [residentId, range, resident, isResampleView, isAbsoluteViewMode]);

  const setOption = React.useCallback(
    (
      residentData: IGetResidentRes | undefined,
      sensorData: [number, number | null][],
      resampleData: [number, number | null][],
      notificationData: ScatterItem[],
      careRecordData: ScatterItem[]
    ) => {
      const option: Highcharts.Options = {
        chart: {
          zoomType: 'x',
          height: 180,
        },
        time: {
          timezoneOffset: new Date().getTimezoneOffset(),
        },
        title: {
          text: '',
        },
        xAxis: {
          type: 'datetime',
          min: Date.parse(range.start.format()),
          max: Date.parse(range.end.format()),
          showEmpty: true,
          opposite: false,
          gridLineWidth: 1,
          lineWidth: 0,
          tickWidth: 0,
          minPadding: 0,
          maxPadding: 0,
          plotLines: [
            {
              color: '#CCCCCC',
              dashStyle: 'Solid',
              value: Date.parse(range.start.format()),
              width: 0.5,
            },
            {
              color: '#CCCCCC',
              dashStyle: 'Solid',
              value: Date.parse(range.end.format()),
              width: 0.5,
            },
          ],
          dateTimeLabelFormats: {
            minute: '%H:%M',
            hour: '%H:00',
            day: t('graphDayFormat', '%m月%d日'),
            week: t('graphDayFormat', '%m月%d日'),
            month: t('graphYearFormat', '%m月%d日'),
          },
          crosshair: {
            width: 2,
          },
        },
        yAxis: {
          title: {
            text: '',
          },
          labels: {
            formatter: function () {
              return yAxisLabelFormatter(this.value, isAbsoluteViewMode);
            },
          },
          plotLines: getYGrids(residentData?.bigBladderNotification, residentData?.bladderThresholdPercentage),
          max: 10,
          min: 0,
          tickInterval: 2,
          crosshair: {
            width: 2,
          },
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          series: {
            marker: {
              enabled: false,
            },
          },
          scatter: {
            marker: {
              enabled: true,
            },
            states: {
              hover: {
                enabled: true,
              },
            },
          },
        },
        series: [
          {
            name: t('urineGraphLabel', '尿量'),
            // @ts-ignore
            data: sensorData,
            yAxis: 0,
            color: '#7BD6E7',
            zIndex: 1,
            type: 'area',
            lineWidth: 3,
            fillOpacity: 0.5,
            tooltip: {
              headerFormat: '<b>{series.name}</b><br>',
              pointFormat: t('graphDateTimeFormat', '{point.y}<br />{point.x:%m月%d日 %H時%M分}'),
              pointFormatter: function () {
                return tooltipFormatter(this.x, this.y, isAbsoluteViewMode);
              },
            },
          },
          {
            name: t('rawGraph', '補正前グラフ'),
            // @ts-ignore
            data: resampleData,
            yAxis: 0,
            zIndex: 0,
            type: 'area',
            fillColor: {
              linearGradient: {
                x1: 0,
                x2: 0,
                y1: 0,
                y2: 1,
              },
              stops: [
                [0, 'rgba(105, 147, 209, 1)'],
                [1, 'rgba(187, 212, 251, 0.2)'],
              ],
            },
            lineWidth: 1,
            lineColor: '#C0C0C0',
            tooltip: {
              headerFormat: '<b>{series.name}</b><br>',
              pointFormatter: function () {
                return tooltipFormatter(this.x, this.y, isAbsoluteViewMode);
              },
            },
            visible: isResampleView,
          },
          {
            name: t('notification', '通知'),
            marker: {
              enabled: true,
              symbol: 'circle',
            },
            zIndex: 3,
            data: notificationData,
            type: 'line', // scatterだとエリア外のマウスオーバーでtooltipがでないためタイプをlineに
            lineWidth: 0,
            states: {
              hover: {
                lineWidth: 0,
                lineWidthPlus: 0,
              },
            },
            tooltip: {
              headerFormat: '<b>{series.name}</b><br>',
              pointFormat: t('graphDateTimePlotFormat', '{point.name}<br />{point.x:%m月%d日 %H時%M分}'),
            },
          },
          {
            name: t('careHistory', 'ケア履歴'),
            marker: {
              enabled: true,
              symbol: 'square',
            },
            zIndex: 3,
            data: careRecordData,
            type: 'line', // scatterだとエリア外のマウスオーバーでtooltipがでないためタイプをlineに
            lineWidth: 0,
            states: {
              hover: {
                lineWidth: 0,
                lineWidthPlus: 0,
              },
            },
            tooltip: {
              headerFormat: '<b>{series.name}</b><br>',
              pointFormat: t('graphDateTimePlotFormat', '{point.name}<br />{point.x:%m月%d日 %H時%M分}'),
            },
          },
        ],
        credits: {
          enabled: false,
        },
      };
      if (range.isCreate) {
        setCharts([
          ...charts,
          {
            id: uuid(),
            option,
            range,
          },
        ]);
      } else {
        setCharts((prev) => {
          if (!prev[0]) {
            return [{ id: uuid(), option, range }];
          }
          prev[0].option = option;
          return [...prev];
        });
      }
    },
    [residentId, range, resident, isResampleView, isAbsoluteViewMode]
  );

  const handleChangeStart = React.useCallback((start) => {
    setRange((prev) => {
      return {
        ...prev,
        start: dayjs(start).local().utc(),
        isCreate: false,
      };
    });
  }, []);

  const handleChangeEnd = React.useCallback((end) => {
    setRange((prev) => {
      return {
        ...prev,
        end: dayjs(end).local().utc(),
        isCreate: false,
      };
    });
  }, []);

  const handleModalClose = React.useCallback(() => {
    setUrineVolumeCaptionOpen(false);
  }, [isUrineVolumeCaptionOpen]);

  React.useEffect(() => {
    if (!range) return;
    (async () => {
      await fetchDataset();
    })();
    fetchCharts();
  }, [range]);

  return (
    <div css={Container}>
      <div css={GraphHeader}>
        <div css={ButtonContainer}>
          <LineChangeButton
            startIcon={<img src='../../../../images/icons/bell.svg' />}
            variant='contained'
            onClick={handleOpenLineChangeModal}
          >
            {t('changeAlmostThreshold', 'そろそろライン変更')}
          </LineChangeButton>
          <div css={ButtonRightElement}>
            {currentNursingHome?.membershipStatus === 'paid' && (
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={false}
                    value={isAbsoluteViewMode}
                    onChange={(v) => {
                      toggleAbsoluteSensorReaction(v.target.checked);
                    }}
                  />
                }
                label={'尿量実測値表示'}
              />
            )}
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={false}
                  value={isResampleView}
                  onChange={(v) => {
                    setResampleView(v.target.checked), toggleResample(v.target.checked);
                  }}
                />
              }
              label={t('rawGraph', '補正前グラフ')}
            />
            <OutlineButton onClick={handleOpenDrawer}>
              <img src='/images/residents/dataset.svg' />
              {t('dataset', 'データセット')}
            </OutlineButton>
            <IconButton onClick={() => setUrineVolumeCaptionOpen(true)}>
              <HelpOutline />
            </IconButton>
          </div>
        </div>
        <div css={CareRecordLabelRow}>
          <div css={CareRecordLabel}>
            <div css={ColorLine}></div>
            <span>{t('almostThreshold', 'そろそろライン')}</span>
          </div>
          <div css={CareRecordLabel}>
            <div css={ColorCircle} style={{ backgroundColor: color.notification_yellow }}></div>
            <span>{t('almostNotificiation', 'そろそろ通知')}</span>
          </div>
          <div css={CareRecordLabel}>
            <div css={ColorCircle} style={{ backgroundColor: color.notification_pink }}></div>
            <span>{t('checkNotificiation', 'でたかも通知')}</span>
          </div>
          <div css={CareRecordLabel}>
            <div css={ColorCircle} style={{ backgroundColor: color.notification_red }}></div>
            <span>{t('gotUpNotificiation', '起き上がり通知')}</span>
          </div>
        </div>
        <div css={CareRecordLabelRow}>
          <div css={CareRecordLabel}>
            <div css={ColorRect} style={{ backgroundColor: '#72FBFD' }}></div>
            <span>
              {t('toilet', 'トイレ')}：{t('yes', '有')} {t('diaperOrPad', 'おむつ / パッド')}：{t('no', '無')}
            </span>
          </div>
          <div css={CareRecordLabel}>
            <div css={ColorRect} style={{ backgroundColor: '#75F94C' }}></div>
            <span>
              {t('toilet', 'トイレ')}：{t('yes', '有')} {t('diaperOrPad', 'おむつ / パッド')}：{t('yes', '有')}
            </span>
          </div>
          <div css={CareRecordLabel}>
            <div css={ColorRect} style={{ backgroundColor: '#CCCCCC' }}></div>
            <span>
              {t('toilet', 'トイレ')}：{t('no', '無')} {t('diaperOrPad', 'おむつ / パッド')}：{t('yes', '有')}
            </span>
          </div>
          <div css={CareRecordLabel}>
            <div css={ColorRect} style={{ backgroundColor: '#6D6D6D' }}></div>
            <span>
              {t('toilet', 'トイレ')}：{t('no', '無')} {t('diaperOrPad', 'おむつ / パッド')}：{t('no', '無')}
            </span>
          </div>
        </div>
      </div>

      <div css={GraphContainer}>
        <div css={DatePickerContainer}>
          <DateTimePicker
            ampm={false}
            views={['year', 'month', 'day', 'hours', 'minutes']}
            value={range.start.local()}
            maxDate={range.end.local()}
            onChange={handleChangeStart}
            renderInput={(params) => <TextField variant={'standard'} {...params} helperText={null} />}
          />
          〜
          <DateTimePicker
            ampm={false}
            views={['year', 'month', 'day', 'hours', 'minutes']}
            value={range.end.local()}
            minDate={range.start.local()}
            maxDate={dayjs().local().add(1, 'day').set('hour', 0).set('minute', 0).set('second', 0)}
            onChange={handleChangeEnd}
            renderInput={(params) => <TextField variant={'standard'} {...params} helperText={null} />}
          />
        </div>
        {charts.length > 0 && (
          <>
            {charts.map((chart, index) => (
              <DetailGraphItem
                key={chart.id}
                id={chart.id}
                graphOption={{ ...chart.option }}
                range={chart.range}
                isFirst={index === 0}
                isResampleView={isResampleView}
                onDelete={handleDeleteGraph}
              />
            ))}
          </>
        )}
      </div>
      {isLineChangeModalOpen && resident && (
        <ChangeLineModal isOpen resident={resident} onClose={handleCloseLineChangeModal} onSubmit={handleUpdateLine} />
      )}
      {isDrawerOpen && datasets && (
        <>
          {datasets.length > 0 && (
            <Drawer sx={{ position: 'relative' }} anchor='right' open={isDrawerOpen} onClose={handleCloseDrawer}>
              <Dataset
                datasets={datasets}
                addGraph={handleCreateChart}
                updateGraph={handleUpdateChart}
                toggleFavorite={handleUpdateFavorite}
                onClose={handleCloseDrawer}
              />
            </Drawer>
          )}
        </>
      )}
      {isUrineVolumeCaptionOpen && (
        <RecordDialog isOpen={isUrineVolumeCaptionOpen} dialogWidth={'620px'} onClose={handleModalClose}>
          <div css={[CaptionTitle, CaptionContainer]}>10段階表示と尿量値の目安</div>
          <div css={CaptionContainer}>
            <span css={CaptionText}>数値0： 100ml以下</span>
            <span css={CaptionText}>数値3： 120ml程度</span>
            <span css={CaptionText}>数値5： 200ml程度</span>
            <span css={CaptionText}>数値8： 320ml程度</span>
            <span css={CaptionText}>数値10：400ml程度</span>
          </div>
          <div css={CaptionContainer}>
            <span css={AnnotationText}>※ 個人により数値のばらつきがあるため、1つの目安としてご参照ください</span>
          </div>
          <MUIButton variant='contained' color='secondary' disableElevation onClick={handleModalClose}>
            {t('close', '閉じる')}
          </MUIButton>
        </RecordDialog>
      )}
    </div>
  );
};
